/* break points */
/* 900px turns into mobile */
/* 650px turns navbar 10px smaller in height. turns several different things smaller */
/* anything lower than those are more specialized for unique situations */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* *:focus {
  border: none;
  outline: none;
} */

:root {
  /* navbar */
  --siteContentsMaxWidth: 1512px;
  /* everything else for the most part */
  --siteContentsMidWidth: 1400px;
  /* not sure yet */
  --siteContentsLeastWidth: 1200px;
  /* imgGalleryMarginBetweenImages is the Image Gallery's margin that separates each image. */
  /* Use imgGalleryMarginBetweenImages on image-gallery__container and image gallery subheading's padding left/right to get equal to other elements' side spacing. */
  /* Use double that value to get side padding for other elements like navbar etc because each image in the gallery has the value on left AND right, so it makes it double when separating the images */
  --imgGalleryMarginBetweenImages: 1rem;
  --sideSpacePadding: 2rem;
  --sideSpacePaddingSmallScreen: calc(var(--imgGalleryMarginBetweenImages) / 2);
  /* colors */
  --dark1: #000;
  --dark2: #2c343e;
  --dark3: #4a4a4a;
  --dark4: #7f7f7f;
  --dark5: #bfbfbf;
  --greyBorder1: #dfdfe0;
  --offWhite: #f7f7f7;
  --white: #fff;
  --ctaYellow: #f5c000;
  --ctaYellowDark: hsl(47, 100%, 47%);
}

[data-hamOpenOrClosed="closed"] {
  --navSearchDisplay: none;
  --hamburgerIconFontSize: 1rem;
  --hamburgerListDisplay: none;
  --logoDisplay: flex;
  /* --logoDisplayMobile: none; */
}

[data-navTheme="transparent"] {
  --navBGColor: rgba(0, 0, 0, 0);
  --navLogoColor: #fff;
  --navArrowIconColor: #fff;
  --navArrowIconColorHover: rgb(209, 209, 209);
  --hamburgerIconColor: #fff;
  --navbarHeightMargin: 0px;
  --navPosition: absolute;
  --navRight: 0;
  --navLeft: 0;
  --navMargin: 0 auto;
}

[data-navTheme="white"] {
  --navBGColor: white;
  --navLogoColor: var(--dark2);
  --navLogoColorMobile: #fff;
  --navLogoBGColor: #dcad00;
  --navArrowIconColor: #a0a0a0;
  --navArrowIconColorHover: rgb(116, 116, 116);
  --navSearchDisplay: flex;
  --hamburgerIconColor: #4a4a4a;
  --navOutline: solid 1px #f0f0f0;
  --navbarHeightMargin: 80px;
}

[data-navTheme="black"] {
  --navBGColor: #4a4a4a;
  --navLogoColor: #fff;
  --navLogoColorMobile: #fff;
  --navLogoBGColor: #dcad00;

  --navArrowIconColor: white;
  --navArrowIconColorHover: rgb(209, 209, 209);
  --navSearchDisplay: flex;
  --hamburgerIconColor: white;
  --navbarHeightMargin: 80px;
}

[data-hamOpenOrClosed="open"] {
  --navBGColor: #000000;
  --navLogoColor: white;
  --navSearchDisplay: flex;
  --hamburgerIconFontSize: 1.41rem;
  --hamburgerListDisplay: block;
  --navOutline: none;
  --logoDisplay: none;
  --logoDisplayMobile: flex;
  --navLogoColorMobile: #fff;
  --navLogoBGColor: #dcad00;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
@media (width<900px) {
  .app {
    min-height: 100%;
  }
}

a {
  text-decoration: none;
}

.overflowYHidden {
  overflow: hidden;
}

.blue {
  color: blue;
}

ul,
li {
  list-style-type: none;
  list-style-position: none;
  margin: 0;
  padding: 0;
}

button {
  border: none;
  background: none;
}

.opacity0 {
  opacity: 0;
}

.gone {
  display: none;
}

.displayNone {
  display: none;
}

.pointer-events__none {
  pointer-events: none;
}

/* Gallery Heading CSS */
.galleryHeadingAndSortContainer {
  margin-top: 3rem;
  margin-bottom: 5rem;
}
.galleryHeading {
  font-size: 2rem;
  text-align: center;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1.25rem;
}
.galleryHeading h2 {
  font-size: 3.125rem;
  padding: 0 1.5rem;
}
.galleryHeading p {
  font-size: 1rem;
  margin-top: -0.6rem;
  color: rgb(118, 118, 118);
  word-wrap: break-word;
  width: 81%;
}

/* Progress Bar CSS */

.progress-bar__main {
  width: 0px;
  height: 4px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(103, 187, 103);
  z-index: 100000;
}
.progress-bar__start {
  animation: progressBar-start;
  animation-duration: 1s;
  animation-timing-function: ease-in;
}

.progress-bar__pause {
  animation-play-state: paused;
}
.progress-bar__finish {
  animation: progressBar-finish;
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
}

@keyframes progressBar-start {
  from {
    width: 0px;
  }
  to {
    width: 100%;
  }
}

@keyframes progressBar-finish {
  from {
    width: 9%;
  }
  to {
    width: 100%;
  }
}

/* NavBar CSS */
.navbar__height-margin {
  height: var(--navbarHeightMargin);
}
.navbar__container {
  background-color: var(--navBGColor);
  display: flex;
  height: 80px;
  justify-content: center;
  outline: var(--navOutline);
  transition: background-color 0.5s;
  z-index: 9;
  position: var(--navPosition);
  left: var(--navLeft);
  right: var(--navRight);
  margin: var(--navMargin);
}
.navbar__contents {
  padding: 0 var(--sideSpacePadding);
  width: 100vw;
  max-width: var(--siteContentsMaxWidth);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 39px;
}
.navbar__logo {
  margin: 0;
  z-index: 1;
  margin-bottom: 1.5px;
  line-height: 1.2;
  display: var(--logoDisplay);
  align-items: center;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--navLogoColor);
  transition: color 0.15s ease-in-out;
}
.navbar__logo__mobile {
  display: none;
  margin: 0;
  z-index: 1;
  margin-bottom: 1.5px;
  line-height: 1.2;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 1.95rem;
  font-weight: 500;
  color: var(--navLogoColorMobile);
  background-color: var(--navLogoBGColor);
  padding: 0.25rem 1rem;
  height: 45px;
  border-radius: 6px;
  transition: color 0.15s ease-in-out;
}
.navbar__logo__mobile:hover {
  color: #e6e6e6;
  background-color: #b78f00;
}
[data-navTheme="transparent"] .navbar__logo:hover {
  color: rgb(207, 207, 207);
}
[data-navTheme="white"] .navbar__logo:hover {
  color: rgb(46, 46, 46);
}
[data-navTheme="black"] .navbar__logo:hover {
  color: rgb(207, 207, 207);
}
.navbar__search-container {
  width: 100%;
  display: var(--navSearchDisplay);
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.navbar__search-bar {
  border: none;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  /* max-width: 38rem; */
  width: 100%;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem 1.25rem;
  padding-right: 32px;
  outline: none;
  color: #747474;
  background-color: #f7f7f7;
  height: 48px;
  animation: fadeIn 0.6s;
}
.navbar__search-button {
  background-color: #f7f7f7;
  height: 48px;
  width: 48px;

  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 1rem;
  position: relative;
  animation: fadeIn 0.6s;
}
.navbar__search-icon {
  color: #747474;
  font-size: 1.1rem;
  transition: 0.05s ease;
  position: absolute;
  top: 30%;
  left: 25%;
}
.navbar__buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  font-size: 1rem;
}
.navbar__login-button {
  color: white;
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;
  transition: 0.2s;
  width: 50px;
  z-index: 1;
}
.navbar__login-button:hover {
  color: rgb(232, 232, 232);
}
[data-navTheme="white"] .navbar__login-button {
  color: #4a4a4a;
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;
  transition: 0.2s;
}
[data-navTheme="white"] .navbar__login-button:hover {
  color: rgb(0, 0, 0);
}
.navbar__account-button-and-list-container {
  position: relative;
  padding-bottom: 0.8rem;
  margin-top: 0.8rem;
  z-index: 100000;
}
.navbar__account-button-container {
  color: rgb(255, 255, 255);
  font-weight: bold;
  border: none;
  border-radius: 10px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.navbar__account-button-hover-overlay-div {
  position: relative;
}
.navbar__account-button-hover-overlay-div:after {
  opacity: 0;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0);
  transition: 0.125s linear;
}
.navbar__account-button-container:hover
  .navbar__account-button-hover-overlay-div::after {
  opacity: 0.2;
}
.navbar__account-button-hover-overlay-div:hover
  .navbar__account-button-hover-overlay-div:after {
  opacity: 0.32 !important;
  transition: 0.3s;
}
.navbar__down-arrow-icon {
  margin-left: 7px;
  margin-top: 6px;
  color: var(--navArrowIconColor);
  transition: color 0.15s linear;
}
.navbar__up-arrow-icon {
  margin-left: 7px;
  margin-top: 5px;
  color: var(--navArrowIconColorHover);
  transition: color 0.1s linear;
}
.navbar__account-button-container:hover .navbar__down-arrow-icon {
  color: var(--navArrowIconColorHover);
}
.navbar__account-button-container:not(:hover) .navbar__up-arrow-icon {
  color: var(--navArrowIconColor);
  transition: 0.1s;
}
.navbar__dropdown-menu-container {
  border: 1px solid rgba(128, 128, 128, 0.642);
  position: absolute;
  right: -10px;
  top: 39px;
  width: 140px;
  border-radius: 8px;
  background-color: white;
  padding: 0.55rem 0;
}
.navbar__dropdown-menu-container::after {
  content: "";
  border: 8.5px solid transparent;
  border-top-color: white;
  position: absolute;
  top: -15px;
  left: 104.5px;
  transform: rotate(180deg);
}
.navbar__dropdown-menu-container::before {
  content: "";
  border: 8.5px solid transparent;
  border-top-color: rgb(176, 176, 176);
  position: absolute;
  top: -16.5px;
  z-index: -1;
  left: 104.5px;
  transform: rotate(180deg);
}
.navbar__dropdown-menu {
  font-weight: 500;
}
.navbar__dropdown-menu li {
  padding: 0.325rem 0.25rem;
  width: 100%;
  cursor: pointer;
}
.navbar__dropdown-menu li a {
  color: #535353;
  font-size: 1rem;
  padding-left: 0.75rem;
  display: inline-block;
  width: 100%;
  text-decoration: none;
}
.navbar__dropdown-menu li button {
  color: #535353;
  font-size: 1rem;
  font-weight: 500;
  padding-left: 0.75rem;
  display: inline-block;
  width: 100%;
  text-align: left;
}
.navbar__dropdown-menu li:hover {
  background-color: rgb(245, 245, 245);
}
.navbar__CTA-button-1 {
  z-index: 1;
  padding: 0.5rem 1.5rem;
  background-color: var(--ctaYellow);
  color: rgb(25, 25, 25);
  font-weight: 500;
  border-radius: 6px;
  font-size: 1rem;
  box-shadow: 0px 0px 0px 1px var(--ctaYellow);
  transition: box-shadow 0.1s ease-out;
}
.navbar__CTA-button-1:hover {
  box-shadow: 0px 0px 0px 0px var(--ctaYellow);
  color: rgb(25, 25, 25);
}
.navbar__CTA-button-1:focus {
  color: black !important;
  /* outline: 2px solid black; */
}
.navbar__smallUploadButton {
  color: white;
  font-size: 0.95rem;
  display: none;
  margin-right: 1rem;
}
.navbar__smallUploadButton:hover {
  color: var(--ctaYellow);
}
.navbar__hamburger-icon-bars {
  display: none;
  position: relative;
  color: var(--hamburgerIconColor);
  font-size: var(--hamburgerIconFontSize);
  z-index: 1;
  width: 20px;
}
.navbar__hamburger-icon-x {
  font-size: 1.5rem;
  color: #f7f7f7;
  padding-left: 3px;
}
.navbar__hamburger-icon-x:focus {
  color: rgb(209, 209, 209) !important;
}
.navbar__hamburger-icon-x:hover {
  color: rgb(209, 209, 209);
}
.navbar__hamburger-list-container {
  overflow-y: auto;
  width: 100vw;
  height: calc(100vh - 80px);
  background-color: #000;
  color: white;
  border-top: 2px solid #212121;
  position: fixed;
  top: 80px;
  left: 0;
  z-index: 9999999;
  animation: fadeIn 0.6s;
  font-size: 2rem;
  padding-bottom: 1.75rem;
  display: var(--hamburgerListDisplay);
}
.navbar__hamburger-list-container ul {
  margin-top: 1.25rem;
  padding-bottom: 1rem;
  padding: 0px 0px 0px var(--sideSpacePadding);
}
.navbar__hamburger-list-container li {
  /* padding-right: 0.4rem !important; */
}
.navbar__hamburger-list-container ul li a {
  color: #f7f7f7;
  font-size: 1.2rem;
  font-weight: 500;
}
.navbar__hamburger-list-container ul li button {
  color: #f7f7f7;
  font-size: 1.2rem;
  font-weight: 500;
}
.navbar__hamburger-list-container ul li a:hover {
  color: var(--dark4);
}
.navbar__hamburger-list-container ul li button:hover {
  color: var(--dark4);
}
.navbar__hamburger-list-container ul li {
  background-color: black !important;
}
.navbar__hamburger-list-grey-divider {
  border-top: 2px solid #212121;
  margin: 30px 0 16px 0px;
  width: 90%;
}
@media (width > 900px) {
  [data-hamOpenOrClosed="closed"] .navbar__logo__mobile {
    display: none;
  }
}
@media (width < 901px) {
  [data-navTheme="transparent"] .navbar__logo__mobile {
    display: none;
  }
  [data-hamOpenOrClosed="open"] .navbar__logo__mobile {
    display: flex;
  }
  [data-navTheme="white"] .navbar__logo {
    display: none;
  }
  [data-navTheme="black"] .navbar__logo {
    display: none;
  }
  [data-navTheme="white"] .navbar__logo__mobile {
    display: flex;
  }
  [data-navTheme="black"] .navbar__logo__mobile {
    display: flex;
  }
}
@media (width < 901px) {
  .navbar__hamburger-icon-bars {
    display: inline;
    position: inherit;
    font-size: 1.41rem;
  }
  .navbar__login-button {
    display: none;
  }
}

@media (width < 650px) {
  .navbar__container {
    height: 70px;
  }
  /* .navbar__height-margin {
    height: 70px;
  } */
  .navbar__hamburger-list-container {
    height: calc(100vh - 70px);
    top: 70px;
  }
  .navbar__contents {
    gap: 1rem;
    padding-left: 15px;
  }
  .navbar__logo {
    font-size: 1.2rem;
  }
  [data-navTheme="white"] .navbar__CTA-button-1 {
    display: none;
  }
  [data-navTheme="black"] .navbar__CTA-button-1 {
    display: none;
  }
  [data-navTheme="transparent"] .navbar__CTA-button-1 {
    padding: 0.48rem 1.4rem;
    border-radius: 4px;
  }
  [data-hamOpenOrClosed="open"] .navbar__CTA-button-1 {
    display: none;
    position: relative;
  }
  .navbar__search-bar,
  .navbar__search-button {
    height: 45px !important;
    font-size: 1.15rem;
    font-weight: 500;
  }
  .navbar__search-bar {
    padding-right: 11px;
  }
  .navbar__search-button {
    width: 50px;
  }
  .navbar__search-icon {
    font-size: 1.15rem !important;
    top: 30% !important;
    left: 28% !important;
  }
  [data-hamOpenOrClosed="open"] .navbar-contents {
    gap: 1.5625rem; /*25px*/
  }
  .navbar__account-button-and-list-container {
    display: none;
    position: relative;
  }
  .navbar__buttons-container {
    gap: 1.3rem;
  }
  [data-hamOpenOrClosed="open"] .navbar__buttons-container {
    gap: 0.3rem;
  }
  .navbar__hamburger-icon-bars {
    font-size: 1.45rem !important;
    margin-bottom: 0.5px;
  }
}

@media (width < 310px) {
  /* .navbar__CTA-button-1 {
    display: none;
    position: relative;
  } */
  [data-hamOpenOrClosed="closed"] .navbar__smallUploadButton {
    display: inline;
    position: inherit;
  }
  .navbar__buttons-container {
    gap: 0.725rem;
  }
}

/* Image Gallery CSS */
.image-gallery__container {
  max-width: var(--siteContentsMidWidth);
  margin: auto;
  margin-top: 0.6rem;
  margin-bottom: 4.25rem;
  /* padding: 0px 6px 0px 6px; */
  padding: 0 var(--imgGalleryMarginBetweenImages);
}
.image-gallery__searchquery-heading {
  margin-top: 2.35rem;
  margin-left: var(--imgGalleryMarginBetweenImages);
  color: #3c3c3c;
}
.image-gallery__search-results-length-subheading {
  font-weight: 300;
  color: var(--dark3);
}
.image-gallery__dropdown-and-subheading-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: var(--siteContentsMidWidth);
  padding: 0 var(--imgGalleryMarginBetweenImages);
  /* background-color: rebeccapurple; */
  margin: auto;
  /* margin-right: var(--sideSpacePaddingSmallScreen); */
}
.image-gallery__dropdown-and-subheading-container h1 {
  padding: 1.65rem 0px 1.3rem 0px;
  /* margin-left: 15px; */
  font-size: 1.7rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.image-gallery__main-page-subheading {
  padding: 1.65rem 0px 0.275rem 0px;
  margin-left: var(--imgGalleryMarginBetweenImages);
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  color: var(--dark2);
}
.image-gallery__likes-page-subheading-container {
  padding: 1.65rem 0px 1.3rem 0px;
  /* margin-left: 15px; */
  font-size: 1.7rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.image-gallery__likes-page-subheading-container a {
  font-size: 1.15rem;
  text-decoration: none;
  color: rgb(68, 68, 68);
}
.image-gallery__likes-page-subheading-container a:hover {
  color: black;
  transition: 0.2s;
}
.image-gallery__likes-page-hover-div {
  position: relative;
}
.image-gallery__likes-page-subheading-img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  object-fit: cover;
  background-color: beige;
}
.image-gallery__likes-page-subheading-img::after {
  opacity: 0;
  display: block;
  content: "";
  position: absolute;
  width: 58px;
  height: 58px;
  border-radius: 50%;
  top: -4px;
  left: -4px;
  background-color: rgb(0, 0, 0);
  transition: 0.125s linear;
}
.image-gallery__likes-page-hover-div:hover
  .image-gallery__likes-page-subheading-img::after {
  opacity: 0.25 !important;
  transition: 0.3s;
}
.image-gallery__likes-page-subheading-username-link {
  border-bottom: 2px dotted rgba(0, 0, 0, 0.3);
  transition: 0.2s;
}
.image-gallery__likes-page-subheading-username-link:hover {
  border-bottom: 2px dotted black;
  transition: 0.4s !important;
}
.image-gallery__sort-filter-dropdown-container {
  gap: 1.25rem;
  /* margin: auto; */
  justify-content: center;
  margin-bottom: 2rem;
  margin-top: 2rem;
}
.image-gallery__dropdown-button {
  display: flex;
}
.image-gallery__dropdown-button .btn-primary {
  background-color: white;
  color: #808080;
  border: none;
  outline: 1px solid var(--greyBorder1);
  border-radius: 4px;
  padding: 0.5rem 1rem;
  font-weight: 500;
  font-size: 1rem;
  width: 100%;
}
.image-gallery__dropdown-button .btn-primary:hover {
  color: gray;
  border: none;
  outline: 1.5px solid rgba(94, 94, 94, 0.412);
  background-color: white;
}
.image-gallery__dropdown-item {
}
.image-gallery__image-container {
  position: relative;
  font-size: 1rem;
  color: rgb(73, 73, 73);
  /* background-color: rgb(248, 245, 235); */
  overflow: hidden;
  overflow-y: hidden;
  margin: var(--imgGalleryMarginBetweenImages);
}
.image-gallery__image-container:hover::after {
  opacity: 1;
  box-shadow: inset 0px 0px 30px 60px rgba(0, 0, 0, 0.15);
  content: "";
  display: inline-block;
  height: 100%;
  position: absolute;
  top: 0;
  left: -390px;
  width: 490%;
  z-index: 0;
  pointer-events: none;
}
.image-gallery__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
.image-gallery__image-overlay-container {
  opacity: 0;
}
.image-gallery__image-container:hover .image-gallery__image-overlay-container {
  opacity: 1;
  position: initial;
}
.image-gallery__like-button-container {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: white;
  text-decoration: none;
  color: rgb(73, 73, 73);
  border-radius: 10px;
  padding: 20.5px;
  z-index: 100;
}
.image-gallery__like-button-container:hover {
  background-color: #f1f1f1;
}
.image-gallery__like-button-icon {
  font-size: 1rem;
  border-radius: 10px;
  pointer-events: none;
  position: absolute;
  z-index: 100;
  top: 13px;
  left: 13.5px;
}
.image-gallery__like-button-icon-filled {
  color: red;
  z-index: 105;
  animation: likeAnimation 0.4s;
}
.image-gallery__download-button-container {
  cursor: pointer;
  position: absolute;
  z-index: 100;
  bottom: 12px;
  right: 10px;
  background-color: white;
  text-decoration: none;
  color: rgb(73, 73, 73);
  border-radius: 10px;
  padding: 8.5px 13px;
}
.image-gallery__download-button-container:hover {
  background-color: #f1f1f1;
}
.image-gallery__download-button-icon {
  z-index: 100;
  pointer-events: none;
  border-radius: 10px;
}
.image-gallery__image-author-link-container {
  cursor: pointer;
  position: absolute;
  bottom: 0px;
  left: 3px;
  text-decoration: none;
  color: #f1f1f1;
  font-weight: 800;
  font-size: 1rem;
  border-radius: 10px;
  padding: 0.75rem;
  z-index: 10;
  display: flex;
  align-items: center;
  gap: 0.425rem;
}
.image-gallery__image-author-link-container:hover {
  color: #f1f1f1;
  text-decoration: underline;
}
.image-gallery__image-author-name-link:hover {
  background: none;
  color: #f1f1f1;
  text-decoration: underline;
}
.image-gallery__image-author-profile-pic {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  object-fit: cover;
}

@media (width < 1000px) {
  .searchHeading {
    font-size: 2rem !important;
    margin-top: 0 !important;
  }
}
@media (width < 901px) {
  .image-gallery__image-container {
    margin: var(--sideSpacePaddingSmallScreen);
  }
  .freeStockPhotosHeading {
    margin-left: var(--sideSpacePaddingSmallScreen);
  }
  .image-gallery__searchquery-heading {
    margin-left: var(--sideSpacePaddingSmallScreen);
  }
}
@media (width < 900px) {
  .image-gallery__dropdown-and-subheading-container {
    padding: 0 var(--sideSpacePaddingSmallScreen);
  }
  .image-gallery__main-page-subheading {
    padding-top: 2.45rem;
    padding-bottom: 1rem;
    margin-left: calc(var(--sideSpacePaddingSmallScreen) - 1.75px);
  }
  .image-gallery__search-results-length-subheading {
    font-size: 1.35rem !important;
    padding-top: 1rem !important;
  }
}
@media (width < 825px) {
  .image-gallery__dropdown-button .btn-primary {
    font-size: 0.85rem;
  }
}
@media (width < 800px) {
  .sortingBarCont1 {
    border-bottom: 1px solid rgb(228, 228, 228);
  }
  .image-gallery__main-page-subheading {
    padding-bottom: 0.275rem;
  }
}
@media (width < 710px) {
  .image-gallery__dropdown-and-subheading-container {
    flex-direction: column;
    align-items: flex-start;
  }
  .image-gallery__likes-page-subheading-container {
    margin-bottom: 0;
  }
  .image-gallery__dropdown-and-subheading-container h1 {
    padding-bottom: 1rem;
  }
  .image-gallery__sort-filter-dropdown-container {
    margin-bottom: 2.5rem;
    margin-left: 3px;
  }
  .image-gallery__sort-filter-dropdown-container {
    margin-top: 0.25rem;
    margin-bottom: 2.7rem;
    flex-direction: column;
    max-width: 99.5%;
  }
  .freeStockPhotosHeading {
    padding-bottom: 0.5rem;
    margin-left: 4px !important;
    margin-top: 2.75rem;
  }
  .image-gallery__sort-filter-dropdown-container {
    margin-bottom: 1.25rem;
  }
  .image-gallery__dropdown-button {
    margin-left: -2.5px !important;
  }
  .image-gallery__dropdown-button .btn-primary {
    font-size: 0.875rem;
    border-radius: 6px;
    width: 95vw;
    height: 40px;
  }
  .image-gallery__dropdown-item {
    width: 88vw;
  }
}
@media (width < 650px) {
  .image-gallery__like-button-container {
    padding: 2px 23px 0px 0px;
    height: 25px;
    width: 25px;
    background-color: rgba(0, 0, 0, 0) !important;
    opacity: 0;
  }
  .image-gallery__like-button-icon {
    top: 2px;
    left: 5px;
  }
  .image-gallery__like-button-icon-filled {
    animation: likeAnimationZoomedIn 0.4s !important;
  }
  .image-gallery__image-overlay-container {
    opacity: 1;
  }
  .image-gallery__like-button-container {
    /* background-color: rgba(0, 0, 0, 0) !important; */
    color: white !important;
  }
  .image-gallery__download-button-container {
    background-color: rgba(0, 0, 0, 0) !important;
    color: white !important;
    font-size: 1.25rem;
    bottom: 10px !important;
    padding-bottom: 0rem !important;
    padding-right: 0.25rem !important;
  }
  .image-gallery__image-author-link-container {
    opacity: 0;
    pointer-events: none;
  }
}
@media (width < 500px) {
  .image-gallery__download-button-container {
    font-size: 1rem;
  }
  .image-gallery__likes-page-subheading-img {
    width: 42px;
    height: 42px;
  }
  .image-gallery__likes-page-subheading-img::after {
    width: 46px;
    height: 46px;
    top: 0.75px;
    left: -2px;
  }
  .image-gallery__likes-page-subheading-container a {
    font-size: 0.9rem;
  }
}
@media (width < 450px) {
  .searchHeading {
    font-size: 1.25rem !important;
    margin-top: 0 !important;
  }
  .image-gallery__dropdown-item {
    width: 87vw;
  }
}

/* User Settings Page */
.user-settings-page__contents-container {
  padding: 3rem var(--sideSpacePadding) 0px var(--sideSpacePadding);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 3rem;
  max-width: 500px;
  margin: auto;
}
.user-settings-page__contents-container header {
  margin: auto;
  color: var(--dark2);
}
.user-settings-page__contents-container h2 {
  margin: auto;
  margin-top: 0.5rem;
  color: #2c343e;
  font-size: 4rem;
  line-height: 1.2;
  text-align: center;
}
@media (width < 900px) {
  .user-settings-page__contents-container h2 {
    font-size: 3.25rem;
  }
}
@media (width < 650px) {
  .user-settings-page__contents-container h2 {
    font-size: 2.75rem;
  }
}
.user-settings-page__change-pfp-container {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  flex-wrap: wrap;
  gap: 2.15rem;
}
.user-settings-page__change-pfp-input {
  opacity: 0;
  font-size: 0px;
  cursor: pointer;
  position: absolute;
  top: 0%;
  left: 0%;
  /* margin: -125px 0 0 -300px; */
  width: 100%;
  height: 100%;
  z-index: 1001;
  background-color: red;
  border-radius: 10px;
}
.user-settings-page__change-pfp-btn {
  position: relative;
  padding: 0.8rem;
  background-color: var(--ctaYellow);
  color: rgb(25, 25, 25);
  font-weight: bold;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
}
.user-settings-page__contents-container h3 {
  color: var(--dark2);
  font-size: 1.25rem;
  font-weight: 500;
}
.user-settings-page__change-bio-container {
  width: 100%;
  position: relative;
}
.user-settings-page__bio-textarea {
  width: 100%;
  border-radius: 4px;
  border: 1px solid lightgray;
  font-size: 1.125rem;
  padding: 0.35rem;
  padding-left: 0.65rem;
  margin-top: 0.6rem;
  height: 5rem;
  resize: none;
  position: relative;
}
.user-settings-page__bio-textarea:focus {
  outline: 1px solid #06acca;
}
.user-settings-page__bio-char-count {
  position: absolute;
  bottom: 41px;
  right: 7px;
  font-size: 0.8rem;
  color: var(--dark4);
}
.user-settings-page__change-email-container {
  width: 100%;
}
.user-settings-page__email-input {
  width: 100%;
  font-size: 1.125rem;
  padding: 0.35rem 0.65rem;
  border-radius: 4px;
  margin-bottom: 6px;
  border: 1px solid lightgray;
}
.user-settings-page__email-input:focus {
  outline: 1px solid #06acca;
}
.user-settings-page__change-bio-email-btn {
  padding: 0.5rem 0.75rem;
  margin-top: 0.5rem;
  background-color: var(--offWhite);
  color: rgb(25, 25, 25);
  font-weight: 300;
  border: none;
  border-radius: 3px;
  font-size: 1rem;
  display: flex;
  gap: 0.5rem;
  cursor: pointer;
}
.user-settings-page__change-bio-email-btn-success {
  padding: 0.5rem 0.75rem;
  margin-top: 0.5rem;
  color: green !important;
  background-color: lightgreen !important;
  pointer-events: none;
  font-weight: 300;
  border: none;
  border-radius: 3px;
  font-size: 1rem;
  display: flex;
  gap: 0.5rem;
}
.user-settings-page__change-bio-email-btn-fail {
  padding: 0.5rem 0.75rem;
  margin-top: 0.5rem;
  color: darkred !important;
  background-color: red !important;
  pointer-events: none;
  font-weight: 300;
  border: none;
  border-radius: 3px;
  font-size: 1rem;
  display: flex;
  gap: 0.5rem;
}
@media (width < 400px) {
  .user-settings-page__email-buttons-container {
    flex-direction: column;
  }
  .user-settings-page__change-bio-email-btn,
  .user-settings-page__change-bio-email-btn-fail,
  .user-settings-page__change-bio-email-btn-success {
    justify-content: center;
  }
}
.user-settings-page__change-pw-del-acc-btn-container {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 3rem;
  width: 100%;
}
.changePWBtn {
  padding: 0.8rem;
  background-color: #d4d4d4;
  color: rgb(25, 25, 25);
  font-weight: bold;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
}
.changePWBtn:hover {
  color: rgb(25, 25, 25);
}
.deleteAccountBtn {
  width: 157px;
  padding: 0.8rem;
  background-color: #de0c0c;
  color: var(--offWhite);
  font-weight: bold;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
}
@media (width < 400px) {
  .deleteAccountBtn,
  .changePWBtn {
    width: 100%;
    text-align: center;
  }
}

/* Page Not Found Page CSS */
.not-found-page__contents-container {
  /* min-height: 95vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-bottom: 3.75rem;
  padding-left: var(--sideSpacePadding);
  padding-right: var(--sideSpacePadding);

  /* margin-top: -7rem; */
  text-align: center;
  /* margin-bottom: 14rem; */
}
.not-found-page__icon {
  font-size: 12rem;
  color: var(--dark2);
}
.not-found-page__message {
  font-size: 4rem;
  margin-bottom: 2rem;
  color: var(--dark2);
}
.not-found-page__link {
  color: #000;
  font-size: 3rem;
  font-weight: 500;
  height: 70px;
}
.not-found-page__link a {
  color: #000;
  border-bottom: 3px dotted #000;
  transition: 0.2s;
}
.not-found-page__link a:hover {
  color: var(--dark4);
  border-bottom: 3px dotted var(--dark4);
  transition: 0.2s;
}
@media (width < 650px) {
  .not-found-page__contents-container {
    margin-bottom: 0.75rem;
  }
  .not-found-page__icon {
    font-size: 6rem;
  }
  .not-found-page__message {
    font-size: 2rem;
  }
  .not-found-page__link {
    font-size: 1.5rem;
  }
}

/* Email Verify Page CSS */
.email-verify-page__container {
  background-color: var(--offWhite);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.email-verify-page__contents-container {
  max-width: var(--siteContentsMaxWidth);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding-bottom: 7rem;
  min-height: 100%;
  color: var(--dark2);
}
.email-verify-page__email-image-success {
  font-size: 12rem;
  color: rgb(29, 152, 29);
}
.email-verify-page__message-container-success {
  text-align: center;
}
.email-verify-page__email-image-container-fail {
  position: relative;
}
.email-verify-page__email-image-envelope-fail {
  font-size: 12rem;
  color: rgb(185, 51, 51);
}
.email-verify-page__email-image-x-fail {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 6rem;
  color: rgb(137, 36, 36);
}
.email-verify-page__message-container-fail {
  text-align: center;
  max-width: 90ch;
}

/* Green Banner CSS */
.green-banner__container {
  max-width: 100vw;
  height: 40px;
  background-color: rgb(29, 152, 29);
}
.green-banner__contents-container {
  max-width: var(--siteContentsMidWidth);
  height: 40px;
  padding-left: var(--sideSpacePadding);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.8rem;
  margin: auto;
}
.green-banner__exclamation-icon {
  font-size: 1.5rem;
  color: rgb(24, 102, 24);
}
.green-banner__contents-container div {
  color: rgb(241, 241, 241);
}

@media (width < 650px) {
  .green-banner__container {
    position: absolute;
    top: 70px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    height: auto;
    width: 100%;
  }
  .green-banner__contents-container {
    padding-right: 2rem;
  }
  .green-banner__exclamation-icon {
    font-size: 0.85rem;
  }
  .green-banner__contents-container div {
    font-size: 0.75rem;
  }
}

/* Red Banner CSS */
.red-banner__container {
  max-width: 100vw;
  width: 100%;
  min-height: 40px;
  padding: 0.5rem 0.5rem 0.5rem 0px;
  background-color: #b20000;
  z-index: 5;
  position: absolute;
  top: 80px;
}
.red-banner__contents-container {
  max-width: var(--siteContentsMidWidth);
  min-height: 40px;
  padding-left: var(--sideSpacePadding);
  padding-right: 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.8rem;
  margin: auto;
}
.red-banner__exclamation-icon {
  font-size: 1.5rem;
  color: #7f0000;
}
.red-banner__contents-container div {
  color: rgb(241, 241, 241);
}

@media (width < 650px) {
  .red-banner__container {
    top: 70px;
  }
  .red-banner__container,
  .red-banner__contents-container {
    min-height: 30px;
  }
  .red-banner__contents-container {
    padding-left: 15px;
    gap: 0.35rem;
  }

  .red-banner__exclamation-icon {
    font-size: 1.25rem;
  }
  .red-banner__contents-container div {
    font-size: 0.75rem;
  }
}

.profile-pic__small {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  object-fit: cover;
}

/* MainPage CSS*/

.mainPage__heroPicture {
  min-height: 510px;
  background-color: #000;
}

/* Home Page Navbar Searchbar */

.carousel__search-container {
  position: relative;
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__search-button {
  background-color: white;
  height: 48px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 1rem;
  padding-left: 2.25rem;
  position: relative;
}

.carousel__search-icon {
  color: #747474;
  font-size: 1.25rem;
  transition: 0.05s ease;
  position: absolute;
  top: 30%;
  left: 31%;
}

.carousel__search-button:hover .carousel__search-icon {
  color: #4d81d6;
  transition: 0.05s ease;
}

.carousel__search-bar {
  border: none;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  max-width: 38rem;
  width: 100%;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem 1.25rem;
  padding-right: 32px;
  outline: none;
  color: #747474;
  height: 48px;
  /* animation: fadeIn 0.6s; */
}

/* Normal Navbar Searchbar */

.carousel__contents {
}

.heroImage {
  width: 100%;
  height: 28.125rem;
  object-fit: cover;
}

.carousel__container {
  max-height: 512px;
  position: relative;
  z-index: 1;
}
.carousel__black-bg-for-image {
  background-color: #000;
  width: 100%;
  height: 512px;
}
.carousel__hero-image {
  /* position: absolute;
  top: 0;
  left: 0; */
  width: 100%;
  height: 512px;
  object-fit: cover;
  opacity: 0.7;
  background-image: url("../src/images/4k/pexels-water-rocks-4k-blur.jpg");
  background-size: cover;
  background-position: 50% 40%;
}
.carousel__hero-image-blur {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 512px;
  object-fit: cover;
}

.carousel__overlay {
  width: 100%;
  font-size: 2rem;
  font-weight: bold;
  position: absolute;
  top: 10.25rem;
  z-index: 100;
  color: white;
  margin: auto;
  padding: 0 16px;
}

.carousel__overlay h1 {
  max-width: 35ch;
  font-weight: 700;
  margin: auto;
  font-size: 2rem;
  line-height: 1.5;
}

.carousel__overlay-categories {
  /* margin-top: 0.8rem; */
  max-width: 70ch;
  font-size: 1rem;
  padding: 1.34rem 0;
  line-height: 1;
  margin: auto;
  display: flex;
  gap: 0.5rem;
}
.carousel__overlay-categories p {
  white-space: nowrap;
}
.carousel__overlay-categories ul {
  display: flex;
  flex-wrap: wrap;
}

.carousel__overlay-categories a {
  padding: 0.25rem 0.13rem;
  color: white;
  transition: 0.1s ease-in-out;
  text-decoration: none;
  font-size: 0.9rem;
}

.carousel__overlay-categories a:hover {
  color: rgb(207, 207, 207);
}

.carousel__overlay-categories p {
  display: inline;
  color: rgb(219, 219, 219);
  font-weight: 400;
}

/*Search Results and My Likes Gallery CSS*/

.sortingBarCont1 {
  display: flex;
  justify-content: center;
  padding: 2rem 0px 1.5rem 0px;
  gap: 3rem;

  max-width: 1500px;
  width: 100%;
  margin: auto;
  /* margin-bottom: 3.95rem; */
  font-weight: 500;
}

.sortingBarCont1 button {
  font-size: 1rem;
}

.fixed {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.imgGalleryCont1 {
  width: 1467px;
  margin: auto;
  margin-top: 0.6rem;
  margin-bottom: 4.25rem;
  padding: 0px 6px 0px 6px;
}

.freeStockPhotosHeading {
  padding: 1.65rem 0px 1.3rem 0px;
  margin-left: 15px;
  font-size: 1.7rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.freeStockPhotosHeading a {
  font-size: 1.15rem;
  text-decoration: none;
  color: rgb(68, 68, 68);
}

.freeStockPhotosHeading a:hover {
  color: black;
  transition: 0.2s;
}

.hoverThisForPFPOverlay {
  position: relative;
}

.freeStockPhotosHeadingImg::after {
  opacity: 0;
  display: block;
  content: "";
  position: absolute;
  width: 58px;
  height: 58px;
  border-radius: 50%;
  top: -4px;
  left: -4px;
  background-color: rgb(0, 0, 0);
  transition: 0.125s linear;
}

.hoverThisForPFPOverlay:hover .freeStockPhotosHeadingImg::after {
  opacity: 0.25 !important;
  transition: 0.3s;
}

.likesPageHeadingUsernameLink {
  border-bottom: 2px dotted rgba(0, 0, 0, 0.3);
  transition: 0.2s;
}

.likesPageHeadingUsernameLink:hover {
  border-bottom: 2px dotted black;
  transition: 0.4s !important;
}

.buttonClicked {
  color: #fff;
  background-color: #000;
  border-radius: 25px;
  padding: 0.75rem 1.17rem;
}

.buttonClicked:hover {
  color: #fff;
}

.buttonNotClicked {
  color: #000;
  border-radius: 25px;
  padding: 0.75rem;
}

.buttonNotClicked:hover {
  color: #000;
}

.paperWorkButtons--container {
  display: flex;
  white-space: nowrap;
  gap: 1.25rem;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  margin: 1.5rem 15px;
  position: relative;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.paperWorkButtons--container::-webkit-scrollbar {
  display: none;
}
@media (width < 455px) {
  .paperWorkButtons--container {
    font-size: 0.8rem;
  }
}
@media (width < 355px) {
  .paperWorkButtons--container {
    flex-direction: column;
  }
}

.paperWorkButtons-overflowArrowLeft {
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 89px;
  left: 0;
  padding: 0.5rem;
  height: 70px;
  background: linear-gradient(
    90deg,
    rgb(255, 255, 255) 30%,
    rgba(0, 212, 255, 0) 100%
  );
  animation: fadeIn 0.2s;
}

.paperWorkButtons-overflowArrowRight {
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 89px;
  right: 0px;
  padding: 0.5rem;
  height: 70px;
  background: linear-gradient(
    270deg,
    rgb(255, 255, 255) 30%,
    rgba(0, 212, 255, 0) 100%
  );
  animation: fadeIn 0.2s;
}

.opacity0 {
  opacity: 0;
  transition: 0.1s;
}

.paperWorkButtons-arrowIcon {
  font-size: 1rem;
  color: rgb(85, 85, 85);
}

.paperwork__main {
  padding: 0 var(--sideSpacePadding);
  margin-top: 4.25rem;
}
@media (width < 650px) {
  .paperwork__main {
    padding: 0 1rem;
  }
}

.inactivePWorkBtn {
  position: relative;
  color: black;
  font-weight: 300;
}

.activePWorkBtn {
  position: relative;
  color: rgb(236, 236, 236) !important;
  background-color: black;
  padding: 0.5rem;
  border-radius: 5px;
  font-weight: 500;
}

@media (width > 400px) {
  .paperWorkButtons-overflowArrowLeft {
    display: none;
  }
  .paperWorkButtons-overflowArrowRight {
    display: none;
  }
}

@keyframes likeAnimation {
  from {
    font-size: 1.25rem;
    top: 10.25px;
    left: 12.1px;
    color: rgb(234, 1, 1);
  }
  to {
    font-size: 1rem;
    top: 13px;
    left: 13.5px;
    color: red;
  }
}

/* Waves background for Join Now on main page */
.box {
  --mask: radial-gradient(52.4px at 50% 71.5px, #000 99%, #0000 101%)
      calc(50% - 52px) 0/104px 51% repeat-x,
    radial-gradient(52.4px at 50% -45.5px, #0000 99%, #000 101%) 50% 26px/104px
      calc(51% - 26px) repeat-x,
    radial-gradient(52.4px at 50% calc(100% - 71.5px), #000 99%, #0000 101%)
      calc(50% - 52px) 100%/104px 51% repeat-x,
    radial-gradient(52.4px at 50% calc(100% + 45.5px), #0000 99%, #000 101%) 50%
      calc(100% - 26px) / 104px calc(51% - 26px) repeat-x;
  -webkit-mask: var(--mask);
  mask: var(--mask);
}

.joinNowContainer {
  background-image: linear-gradient(
    45deg,
    var(--ctaYellow),
    orange,
    var(--ctaYellow)
  );
  min-height: 440px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  gap: 1.5rem;
  margin-top: -0.5rem;
  margin-bottom: -2.25rem;
  padding-top: 3.25rem;
}

.joinNowContainer h2 {
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  line-height: 1;
}

.joinNowContainer h3 {
  font-weight: 400;
  font-size: 1.5rem;
  text-align: center;
  line-height: 1;
}

.joinNowContainer a {
  text-decoration: none;
  color: black;
  margin-bottom: 4.5rem;
  margin-top: 2.75rem;
  /* background-color: var(--ctaYellow); */
  box-shadow: 2.5px 2.5px 6px -1px rgb(70, 70, 70);
  color: black;
  border: 3px solid black;
  padding: 0.5rem 5.5rem;
  border-radius: 25px;
  font-size: 1rem;
  font-weight: 700;
  transition: 0.2s;
}

.joinNowContainer button {
  margin-bottom: 4.5rem;
  /* background-color: var(--ctaYellow); */
  box-shadow: 2.5px 2.5px 6px -1px rgb(70, 70, 70);
  color: black;
  border: 3px solid black;
  padding: 0.5rem 5.5rem;
  border-radius: 25px;
  font-size: 1.125rem;
  font-weight: 700;
  transition: 0.2s;
}

.joinNowContainer a:hover {
  background-color: var(--offWhite);
  box-shadow: 2.5px 2.5px 7px 0px rgb(90, 90, 90);

  padding: 0.5rem 6rem;

  transition: 0.2s;
}

@keyframes likeAnimationZoomedIn {
  from {
    font-size: 1.25rem;
    top: 0px;
    left: 3.5px;
    color: rgb(234, 1, 1);
  }
  to {
    font-size: 1rem;
    /* top: 13px; */
    /* left: 13.5px; */
    color: red;
  }
}

.likeButtonHeart2 {
  border-radius: 10px;
  pointer-events: none;
  /* position: absolute; */
  z-index: 100;
  color: var(--dark5);
}

.likeButtonLikedFill2 {
  color: red;
  z-index: 105;
  animation: likeAnimation2 0.4s;
}

@keyframes likeAnimation2 {
  from {
    font-size: 1.45rem;
    /* top: 11.65px;
    left: 12.1px; */
    color: rgb(234, 1, 1);
  }
  to {
    font-size: 1.2rem;
    /* top: 14px;
    left: 14px; */
    color: red;
  }
}

.galleryContainer {
}
.galleryHeadingAndSortContainer {
  /* height: 130px;
  padding: 1.25rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 72%; */
}

/* Image View Page CSS */

.subBarCont1 {
  position: fixed;
  display: block;
  background-color: rgb(255, 255, 255);
  /* width: 100vw; */
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  gap: 40px;
}

.subBarAuthor1 {
  display: flex;
  /* align-items: center; */
  gap: 1rem;
}

.subBarLikeDownload1 {
  display: flex;
  gap: 1.2rem;
}

.profilePic {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color: beige;
}

.profilePicBig {
  border-radius: 50%;
  width: 130px;
  height: 130px;
  background-color: beige;
  object-fit: cover;
}

.imageViewDetailsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 100vw; */
  flex-direction: column;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.imgViewPageTitleLikesCont {
  display: flex;
  /* width: 100vw; */
  height: 100px;
  align-items: center;
  justify-content: space-between;
  padding-left: 6.75rem;
  padding-right: 6.75rem;
}

/* My Pics Page CSS */

.mypics-page__container {
  overflow: hidden;
}
@media (width < 900px) {
  .mypics-page__container {
    overflow: visible;
  }
}
.mypics-page__above-gallery-section-container {
  overflow: hidden;
  margin-bottom: 3.5rem;
}
@media (width < 900px) {
  .mypics-page__above-gallery-section-container {
    margin-bottom: 2.375rem;
  }
}
.mypics-page__heading-container {
  /* height: 130px; */
  /* background-color: rebeccapurple; */
  padding: 1.25rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 72%;
  padding: 2rem;
  margin: auto;
  /* margin-top: 1.5rem; */
}
.mypics-page__heading {
  font-size: 2rem;
  text-align: center;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1.25rem;
}
.mypics-page__heading h2 {
  font-size: 3.125rem;
}
.mypics-page__heading p {
  font-size: 1rem;
  margin-top: -1.6rem;
  color: rgb(118, 118, 118);
}

/*My Pics Gallery CSS*/

.mypics-image-gallery__gallery-and-editor-container {
  display: grid;
  grid-template-columns: 70% 1fr;
  grid-template-rows: auto;
  /* width: 93vw; */
  max-width: var(--siteContentsMidWidth);
  margin: auto;
}
@media (width < 900px) {
  .mypics-image-gallery__gallery-and-editor-container {
    display: flex;
    flex-direction: column-reverse;
  }
}
.mypics-image-gallery__sort-bar-container {
  gap: 20px;
  padding: 1rem;
  padding-left: 1.5rem;
  background-color: #e0eaf0;
  /* background-color: #fff; */
  /* border-top: 2px solid rgba(169, 169, 169, 0.21); */
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #bbcad3;
}
@media (width < 900px) {
  .mypics-image-gallery__sort-bar-container {
    position: sticky;
    top: 79px;
    z-index: 99;
  }
}
@media (width < 650px) {
  .mypics-image-gallery__sort-bar-container {
    position: sticky;
    top: 69px;
    z-index: 99;
  }
}
.mypics-image-gallery__sort-bar__sort-filter-dropdown-checkbox-container {
  display: flex;
  /* align-items: center; */
  gap: 0.5rem;
}
.mypics-image-gallery__checkbox-select-all {
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.mypics-image-gallery__dropdown-button {
  display: flex;
  width: 125px !important;
}
.mypics-image-gallery__dropdown-button .btn-primary {
  background-color: white;
  color: #808080;
  border: none;
  outline: 1px solid var(--greyBorder1);
  border-radius: 4px;
  padding: 0.25rem 1rem;
  font-weight: 300;
  font-size: 0.75rem;
  width: 120px;
  height: 25px;
  margin: 0;
}
.mypics-image-gallery__dropdown-button .btn-primary:hover {
  color: gray;
  border: none;
  outline: 1.5px solid rgba(94, 94, 94, 0.412);
  background-color: white;
}

.mypics-image-gallery__sort-bar__download-delete-all-btn-container {
  display: flex;
  align-items: center;
  gap: 1.65rem;
  margin-right: 2.5rem;
  font-size: 1.14rem;
  height: 1px;
}
@media (width < 530px) {
  .mypics-image-gallery__dropdown-button {
    width: 90px !important;
  }
  .mypics-image-gallery__dropdown-button .btn-primary {
    /* width: 1000px; */
    padding: 0;
  }

  .mypics-image-gallery__sort-bar__download-delete-all-btn-container {
    margin-right: 0.5rem;
    gap: 1.2rem;
  }
}
.mypics-image-gallery__container {
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  gap: 40px;
  padding-left: 20px;
  padding-right: 40px;
  padding-top: 1rem;
  padding-bottom: 7.5rem;
  background-color: rgba(198, 215, 228, 0.545);
  /* border-top: 2px solid rgba(169, 169, 169, 0.21); */
  height: 100%;
  overflow: scroll;
}
@media (width < 900px) {
  .mypics-image-gallery__container {
    gap: 0px;
    flex-direction: column;
    align-content: normal;

    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    background-color: #fff;
  }
}
.mypics-image-gallery__img-and-info-container {
  width: 320px;
  height: 280px;
  background-color: #ffffff;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.589);
  position: relative;
  transition: box-shadow 0.2s !important;
}
.mypics-image-gallery__img-and-info-container:hover {
  background-color: #f3f3f3;
  box-shadow: 0px 5px 8px 0px rgba(85, 85, 85, 0.673);
  transition: 0.3s ease-in-out;
}
.mypics-image-gallery__img-and-info-container:hover .checkbox {
  display: block;
}
.mypics-image-gallery__img-label {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.checkbox {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
}
.checkbox-mobile {
  width: 25px;
  height: 25px;
  cursor: pointer;
  display: none;
  pointer-events: none;
  position: absolute;
  top: 44%;
  right: var(--sideSpacePadding);
}
/* in my pics page, if an image is checked/selected, show all remaining checkboxes without having to hover over them */
[data-isACheckboxChecked="true"] .checkbox {
  display: block;
}
[data-isACheckboxChecked="false"] .checkbox {
  display: none;
}
.border {
  outline: 2px solid rgba(15, 15, 199, 0.641);
  box-shadow: 0px 5px 8px 0px rgba(85, 85, 85, 0.673);
  margin: 0;
  padding: 0;
  border: none !important;
}
.mypics-image-gallery__img-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 200px;
}
.mypics-image-gallery__img {
  width: 100%;
  display: block;
  object-fit: contain;
}
.mypics-image-gallery__img-info-container {
  width: 320px;
  height: 70px;
  padding: 0rem 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.mypics-image-gallery__img-info-title {
  padding-top: 1.35rem;
  margin-bottom: 0.275rem;
  width: 290px;
  font-weight: 500;
  font-size: 1.15rem;
  display: block;
  text-overflow: ellipsis !important;
  white-space: nowrap;
  overflow: hidden;
}
.mypics-img-gallery__img-info-size-container {
  display: flex;
  gap: 1.5rem;
  font-size: 0.875rem;
}
@media (width < 900px) {
  .mypics-image-gallery__img-and-info-container {
    width: 100%;
    /* padding: 0 var(--sideSpacePadding); */
    height: 200px;
    margin-top: 1px;
    box-shadow: none;
    border-bottom: 0.5px solid rgb(243, 243, 243);
    overflow: hidden;
  }
  .checkbox {
    display: none;
    pointer-events: none;
  }
  .mypics-image-gallery__img-and-info-container:hover .checkbox {
    display: none;
    pointer-events: none;
  }
  [data-isACheckboxChecked="true"] .checkbox {
    display: none;
  }
  .mypics-image-gallery__img-and-info-container:hover {
    background-color: #fff;
    box-shadow: none;
    transition: 0.3s ease-in-out;
  }
  .checkbox-mobile {
    display: block;
    pointer-events: all;
  }
  .border {
    box-shadow: none !important;
    /* border-bottom: 0.5px solid rgb(243, 243, 243) !important; */
    background-color: #f3f3f3 !important;

    /* margin-top: 1px; */
    /* outline: 2px solid rgba(15, 15, 199, 0.641); */
    border-top: 1px solid rgba(15, 15, 199, 0.641) !important;
    border-bottom: 1px solid rgba(15, 15, 199, 0.641) !important;
  }
  .mypics-image-gallery__img-label {
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 1rem;
    padding: 1.25rem;
  }
  .mypics-image-gallery__img-container {
    display: block;
    width: 115px;
    height: auto;
  }
  .mypics-image-gallery__img {
    width: 115px;
  }
  .mypics-image-gallery__img-info-container {
    width: 71%;
    padding: 0;
    min-height: 90px;
    height: auto;
  }
  .mypics-image-gallery__img-info-title {
    padding: 0;
    width: 400px;
    font-size: 1.4rem;
    line-height: 2.5;
    align-items: center;
    /* background-color: red; */
  }
}
@media (width < 650px) {
  .mypics-image-gallery__img-and-info-container {
    height: 120px;
  }
  .mypics-image-gallery__img-container {
    display: block;
    width: 70px;
    height: auto;
  }
  .mypics-image-gallery__img {
    width: 70px;
  }
  .mypics-image-gallery__img-info-container {
    width: 80%;
    padding: 0;
  }
  .mypics-image-gallery__img-info-title {
    padding: 0;
    align-items: center;
    width: 360px;
    font-size: 1.15rem;
    line-height: 1.85;
  }
}
@media (width < 530px) {
  .mypics-image-gallery__img-and-info-container {
  }
}
@media (width < 520px) {
  .mypics-image-gallery__img-info-container {
    width: 80%;
    padding: 0;
  }
  .mypics-image-gallery__img-info-title {
    padding: 0;
    align-items: center;
    width: 285px;
  }
}
@media (width < 460px) {
  .mypics-image-gallery__img-info-container {
    width: 70%;
    padding: 0;
  }
  .mypics-image-gallery__img-info-title {
    padding: 0;
    align-items: center;
    width: 162px;
  }
}
@media (width < 380px) {
  .mypics-image-gallery__img-info-container {
    /* padding: 0rem 0; */
  }
}
.heightmore {
  min-height: 390px;
  height: auto;
  display: flex;
  align-items: center;
}

.massIcon {
  opacity: 0.6;
  cursor: pointer;
}

.massIcon:hover {
  opacity: 1;
}

.massIconText {
  position: absolute;
  top: 2rem;
  left: -1.25rem;
  background-color: rgb(2, 7, 53);
  color: white;
  font-size: 0.75rem;
  text-align: center;
  padding: 0.25rem;
  border-radius: 4px;
}

/* My Pics Editor Form CSS */

.my-pics-editor__container {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 0.6rem;
  padding-bottom: 4.5rem;
  min-height: 89vh;
  border-top: 2px solid rgba(169, 169, 169, 0.21);
  background-color: #f2f2f2;
}

.my-pics-editor__eye-icon {
  font-size: 1.75rem;
  opacity: 0.4;
}
.my-pics-editor__choose-image-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  margin-top: 2rem;
}
.my-pics-editor__choose-image-div p {
  opacity: 0.5;
}

.my-pics-editor__editor-form-container {
  max-width: 25vw;
}
.my-pics-editor__editor-form-contents-container {
}

#my-pics-editor__preview-image-for-editor {
  max-width: 100%;
  max-height: 300px;
  margin-bottom: 1rem;
  margin-top: 1.75rem;
  outline: 4px solid rgb(233, 233, 233);
}
.my-pics-editor__editor-form-details-container {
}
.my-pics-editor__editor-form-details-sub-container {
  font-size: 1.25rem;
  margin-top: 1.7rem;
  position: relative;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center; */
}
.my-pics-editor__editor-form-details-sub-container input {
  width: 100%;
  border-radius: 4px;
  border: 1px solid lightgray;
  font-size: 0.85rem;
  /* font-weight: 600; */
  padding: 0.35rem;
  padding-left: 0.5rem;
  margin-top: 0.6rem;
}
.my-pics-editor__editor-form-details-sub-container textarea {
  width: 100%;
  border-radius: 4px;
  border: 1px solid lightgray;
  font-size: 0.85rem;
  /* font-weight: 600; */
  padding: 0.35rem;
  padding-left: 0.5rem;
  margin-top: 0.6rem;
  /* min-height: 16.5vw; */
  resize: none;
}
.my-pics-editor__description-char-count {
  position: absolute;
  bottom: -1rem;
  right: 1.05rem;
  font-size: 0.8rem;
  color: var(--dark4);
}
.my-pics-editor__description-char-count-mobile {
  font-size: 0.6rem;
  color: var(--dark4);
}
.textArea1 {
  /*idk where this is used at at this point*/
  width: 100%;
  border-radius: 4px;
  border: 1px solid lightgray;
  font-size: 0.85rem;
  /* font-weight: 600; */
  padding: 0.35rem;
  padding-left: 0.5rem;
  margin-top: 0.6rem;
  height: 5rem;
  resize: none;
}
.my-pics-editor__editor-form-details-sub-containerInput {
  width: 100%;
  border-radius: 4px;
  /* border: 2px solid black; */
  /* border: none; */
  font-size: 0.85rem;
  font-weight: 600;
  padding: 0.35rem;
  padding-left: 0.5rem;
  margin-top: 0.6rem;
}
.my-pics-editor__editor-form-details-sub-containerInputRed {
  width: 100%;
  border-radius: 4px;
  border: 2px solid rgb(151, 0, 0);
  /* border: none; */
  font-size: 0.85rem;
  font-weight: 600;
  padding: 0.35rem;
  padding-left: 0.5rem;
  margin-top: 0.6rem;
}
.my-pics-editor__editor-form-details-sub-containerInputRed:focus {
  /* border: none; */
  border: 2px solid rgb(151, 0, 0);
  outline: none;
}
.my-pics-editor__editor-form-details-sub-containerInput:focus {
  /* border: none; */
  border: 2px solid black;
  outline: none;
}
.my-pics-editor__image-type-and-btns-container {
}
.my-pics-editor__btns-container {
  display: flex;
  align-items: center;
  gap: 0.7rem;
  margin-top: 1.85rem;
  height: 2rem;
}
.my-pics-editor__btns-container button {
  height: 100%;
  font-size: 0.85em;
  background-color: rgb(250, 250, 250);
  padding: 0 0.75rem;
  border: 1px solid lightgrey;
  border-radius: 2px;
}
.my-pics-editor__btns-container a {
  height: 100%;
  font-size: 0.85em;
  background-color: rgb(250, 250, 250);
  color: #000;
  padding: 0.25rem 0.75rem;
  border: 1px solid lightgrey;
  border-radius: 2px;
}
.my-pics-editor__del-yes-or-no {
  position: absolute;
  background-color: #b20000;
  font-size: 1.25rem;
  color: rgb(255, 255, 255);
  display: flex;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
  max-width: 250px;
  top: 18px;
  left: 0px;
  box-shadow: 3px 3px 5px 0px rgb(196, 188, 188);
  padding: 0.5rem 1rem;
}
.my-pics-editor__mass-del-yes-or-no {
  position: absolute;
  background-color: #b20000;
  font-size: 1.25rem;
  color: rgb(255, 255, 255);
  display: flex;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
  max-width: 250px;
  top: -60px;
  left: -80px;
  box-shadow: 3px 3px 5px 0px rgb(196, 188, 188);
  padding: 0.5rem 1rem;
}
.my-pics-editor__del-yes-or-no-heading {
}
.my-pics-editor__del-yes-or-no-btns-container {
  display: flex;
}
.my-pics-editor__del-yes-or-no-btns-container button {
  font-size: 1.25rem;
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border: none;
}
.my-pics-editor__mass-del-yes-or-no button {
  padding: 0 0.65rem;
}
.my-pics-editor__del-yes-or-no-btns-container button:hover {
  background-color: #dddddd;
}
.my-pics-editor__mass-del-yes-or-no-btns-container button:hover {
  background-color: #dddddd;
}
.my-pics-editor__editor-form-container-mobile-open {
  /* min-height: 300px; */
  /* background-color: cadetblue; */
  width: 88%;
  /* background-color: rebeccapurple; */
  /* padding-top: 0.5rem; */
  /* margin-top: -2.9rem; */
}
/* @media (width > 530px) {
  .my-pics-editor__editor-form-container-mobile-open {
    margin-top: -2.6rem;
  }
} */
@media (width < 460px) {
  .my-pics-editor__editor-form-container-mobile-open {
    width: 79%;
  }
}
.my-pics-editor__editor-form-contents-container-mobile-open {
  min-height: 300px;
  /* background-color: oldlace; */
}
@media (width < 380px) {
  .my-pics-editor__editor-form-contents-container-mobile-open {
    /* margin-top: -2rem; */
  }
}
@media (width < 530px) {
  .my-pics-editor__editor-form-details-sub-containerInput {
    width: 100%;
    /* height: 1.75rem; */

    border-radius: 4px;
    /* border: 2px solid black; */
    /* border: none; */
    font-size: 0.85rem;
    font-weight: 600;
    padding: 0.35rem;
    padding-left: 0.5rem;
    /* margin-top: 0; */
  }
}
.my-pics-editor__editor-form-details-container-mobile-open {
  /* height: 300px; */
  display: flex;
  flex-direction: column;
  gap: 0.55rem;
  margin-top: 1.9rem;
  /* width: 390px; */
}
@media (width < 650px) {
  .my-pics-editor__editor-form-details-container-mobile-open {
    width: auto;
  }
}
.my-pics-editor__editor-form-details-sub-container-mobile-open {
}
.my-pics-editor__editor-form-details-sub-container-mobile-open textarea {
  width: 100%;
  border-radius: 4px;
  border: 1px solid lightgray;
  font-size: 0.85rem;
  /* font-weight: 600; */
  padding: 0.35rem;
  padding-left: 0.5rem;
  margin-top: 0;
  /* min-height: 16.5vw; */
  resize: none;
}
.my-pics-editor__editor-form-details-sub-container-mobile-open input {
  width: 100%;
  /* height: 1.75rem; */
  font-size: 0.85rem;
  border-radius: 4px;
}
.my-pics-editor__image-type-and-btns-container-mobile-open {
  display: flex;
  min-height: 30px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 0.5rem;
}
@media (width < 380px) {
  .my-pics-editor__image-type-and-btns-container-mobile-open {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
  }
}
.my-pics-editor__btns-container-mobile-open {
  display: flex;
  gap: 1rem;
}
.my-pics-editor__btns-container-mobile-open button {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
}
.my-pics-editor__btns-container-mobile-open a {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  text-align: center;
}
@media (width < 900px) {
  .my-pics-editor__container {
    position: sticky;
    top: 80px;
    border-bottom: 2px solid rgba(169, 169, 169, 0.21);
    padding: 0;
  }
  .my-pics-editor__choose-image-div {
    margin-top: 1rem;
  }

  .my-pics-editor__editor-form-container {
    max-width: 100%;
  }
  .my-pics-editor__editor-form-contents-container {
    display: grid;
    grid-template-columns: 0.7fr 2fr;
    gap: 2rem;
    padding: 1rem;
  }
  .my-pics-editor__editor-form-details-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 0.9rem;
    /* background-color: red; */
  }
  .my-pics-editor__editor-form-details-sub-container {
    margin-top: 0.1rem;
    /* background-color: rebeccapurple; */
    display: flex;
    flex-direction: column;
  }
  .my-pics-editor__image-type-and-btns-container {
    display: flex;
    align-items: center;
    height: 40px;
  }
  .my-pics-editor__btns-container {
    margin-top: 0;
    margin-left: 1rem;
  }
}
@media (width < 650px) {
  .my-pics-editor__container {
    position: sticky;
    top: 70px;
  }
}
@media (width < 900px) {
  .my-pics-editor__container {
    display: none;
  }
}

.mypics-image-gallery__img-container:hover
  .mypics-image-gallery__container-img {
}

.gallery a:hover .image-overlay-contents-delete {
  opacity: 1;
  transition: 0.12s ease;
}

.image-overlay-contents-like {
  color: white;
  font-size: 20px;
  position: absolute;
  left: 94%;
  top: 90%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.likeButton {
  font-size: 2.25rem;
  transition: 0.14s ease;
}

.likeButton:hover {
  font-size: 41px;
  transition: 0.1s ease;
}

.image-overlay-contents-delete {
  color: white;
  font-size: 20px;
  position: absolute;
  left: 92%;
  top: 9%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  opacity: 0;
}

.deleteButton {
  font-size: 30px;
  transition: 0.14s ease;
}

.deleteButton:hover {
  font-size: 35px;
  transition: 0.1s ease;
}

.mypics-image-gallery__container::after {
  content: "";
  flex-grow: 999;
}

/* mypics gallery scrollbar */

.mypics-image-gallery__container::-webkit-scrollbar {
  width: 10px;
}

.mypics-image-gallery__container::-webkit-scrollbar-thumb {
  background: rgb(60, 92, 161);
  border-radius: 5px;
  height: 10px;
}

.mypics-image-gallery__container::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}

.footerContainer {
  color: grey;
  background-color: rgb(45, 45, 45);
  font-size: 1rem;
  height: 10%;
  padding: 2rem var(--sideSpacePadding);
  /* margin: auto; */
  margin-top: auto;
}

@media (width < 650px) {
  .footerContainer {
  }
}
.footer__contents-container {
  /* background-color: rebeccapurple; */
  /* padding-top: 1.15rem; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
  max-width: var(--siteContentsMidWidth);
}
.footer__contents-container-padding-top-main-page {
  padding-top: 1.15rem;
}

.footerContainer h4 {
  color: white;
  font-size: 1.2rem;
}

.footerUnderline {
  margin-bottom: 0.75rem;
  width: 3.7rem;
  height: 4px;
  background-color: orange;
}

.footerUnderline2 {
  margin-bottom: 0.75rem;
  width: 4rem;
  height: 4px;
  background-color: orange;
}

.footerContainer a {
  font-size: 1rem;
  font-weight: 300;
  color: rgb(194, 193, 193);
  text-decoration: none;
}

.footerContainer a:hover {
  color: rgb(220, 218, 218);
}
.footerContainer button {
  font-size: 1rem;
  font-weight: 300;
  color: rgb(194, 193, 193);
  text-decoration: none;
}

.footerContainer button:hover {
  color: rgb(220, 218, 218);
}

.footerContainer li {
  padding: 0.1rem 0;
}

.footerText {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.imageViewContainer {
  display: flex;
  flex-direction: row;
  margin-top: 140px;
}

/* Upload Page CSS */

.upload-page__container {
  min-height: 550px;
  background-color: purple;
  width: 100%;
}
.upload-page__form-and-list-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  padding: 3rem 0 0 0;
}
@media (width < 650px) {
  .upload-page__form-and-list-container {
    padding: 2.1rem 0 0rem 0;
  }
}
.uploadPageTitle {
  font-size: 36px;
  font-weight: 700;
}
.upload-page__upload-form {
  position: relative;
  border-radius: 25px;
  margin: auto;
  background-color: rgba(255, 255, 255, 0);
  border: 4px dashed var(--dark5);
  width: 85%;
  max-width: var(--siteContentsLeastWidth);
  display: flex;
  justify-content: center;
  padding: 1rem 1rem;
  /* margin-top: 3.25rem; */
  /* margin-bottom: 1rem; */
  transition: 0.1s ease;
  /* background-color: red; */
}
.upload-page__upload-form-hover-div {
  position: absolute;
  opacity: 0;
  z-index: 100000000000;
  top: 0;
  left: 0;
  width: 100%;
  max-width: var(--siteContentsLeastWidth);
  height: 100%;
  background-color: red;
}
.upload-page__upload-form-drag-enter {
  background-color: rgba(186, 207, 251, 0.918);
  border: 4px dashed #3789d1;
  transition: 0.2s;
}
.upload-page__upload-form-drag-enter-not-verified {
  background-color: rgba(255, 224, 224, 0.918);
  border: 4px dashed #d13737;
  transition: 0.2s;
}
.upload-page__upload-form-gallery-icon {
  width: 85px;
}

.upload-page__upload-form .upload-page__add-upload-button {
  background-color: rgb(56, 104, 207);
}

.upload-page__upload-form-contents {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* background-color: blue; */
  /* gap: 2rem; */
}

.upload-page__upload-form-contents-input-for-drag {
  opacity: 0;
  font-size: 0px;
  /* cursor: pointer; */
  position: absolute;
  top: -20px;
  left: 0px;
  /* margin: -125px 0 0 -300px; */
  width: 100%;
  max-width: var(--siteContentsLeastWidth);
  height: 110%;
  z-index: 998;
  background-color: rgb(68, 28, 28);
}

.upload-page__upload-form-input-buttons-and-captions {
  display: flex;
  flex-direction: column;
  /* height: 200px; */
  justify-content: center;
  align-items: center;
  gap: 1.1rem;
  margin-top: 1rem;
}

.upload-page__upload-form-heading {
  max-width: 250px;
  text-align: center;
  font-size: 2.125rem;
  font-weight: 500;
  line-height: 1.2;
}
.upload-page__add-upload-button-container {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 6px;
  margin-top: 25px;
  width: 8.5rem;
  height: 3.15rem;
}
.upload-page__upload-form-contents-input-for-click {
  opacity: 0;
  font-size: 0px;
  cursor: pointer;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  z-index: 999;
  background-color: rgb(255, 0, 0);
}
.upload-page__add-upload-button-button {
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: rgb(60, 113, 226);
  color: #fff;
  cursor: pointer;
  border-radius: 6px;
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
  height: 100%;
}

.upload-page__upload-form-contents-input-for-click:hover
  .upload-page__add-upload-button {
  background-color: rgb(53, 101, 204);
  transition: 0.1s ease;
}

.upload-page__upload-form-caption {
  margin-top: 65px;
  /* margin-bottom: 1rem; */
  font-weight: 400;
  opacity: 0.5;
  text-align: center;
}
.upload-page__uploading-spin-icon {
  color: var(--dark2);
  font-size: 2.5rem;
  margin-right: 1.65rem;
}
.upload-page__trash-icon {
  color: #fff;
  font-size: 4rem;
  margin-right: 1rem;
  transition: color 0.3s;
  cursor: pointer;
}
.upload-page__trash-icon:hover {
  color: var(--dark5);
  transition: 0.3s;
}
.upload-page__uploading-icon {
  margin-right: 1rem;
}

.removeUploadIcon {
  cursor: pointer;
  color: red;
}

.removeUploadIcon:hover {
  color: rgb(182, 32, 32);
}

.removeUploadIconSVG {
  pointer-events: none;
}

.upload-page__file-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1.7rem;
  margin-bottom: 2.25rem;
}
@media (width < 650px) {
  .upload-page__file-list {
    margin-top: 1.1rem;
    margin-bottom: 2.25rem;
  }
}

.upload-page__image-item {
  margin: auto;
  /* margin-top: -2.7rem; */

  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--offWhite);
  color: var(--dark2);
  width: 85%;
  max-width: var(--siteContentsLeastWidth);
  height: 300px;
  font-size: 1.8rem;
  font-weight: 500;
  /* height: 120px; */
  padding: 2rem;
  border-radius: 20px;
  /* animation: fadeIn 0.4s; */
}
/* @media (width < 650px) {
  .upload-page__image-item {
    margin-top: -1.05rem;
  }
} */
.upload-page__image-item-div {
  width: 300px;
  height: 280px;
  background-color: #e9e9e9;
  position: relative;
  overflow: hidden;
}
.upload-page__image-item-div::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  transform: translateX(-100%);
  background: linear-gradient(
    90deg,
    #e9e9e9 0%,
    #e9e9e9 20%,
    #ffffff 50%,
    #e9e9e9 80%,
    #e9e9e9 100%
  );
  animation: skeletonAnimation 3s infinite;
}
.upload-page__image-item-title {
  -ms-word-break: break-all;
  word-break: break-all;
  padding: 0rem 1.25rem;
}
.upload-page__image-item-title-skeleton-loading {
  flex-grow: 1;
  max-width: 400px;
  height: 3rem;
  border-radius: 10px;
  background-color: #e9e9e9;
  margin: 0 1.75rem;
  position: relative;
  overflow: hidden;
}
.upload-page__image-item-title-skeleton-loading::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  transform: translateX(-100%);
  background: linear-gradient(
    90deg,
    #e9e9e9 0%,
    #e9e9e9 30%,
    #ffffff 50%,
    #e9e9e9 70%,
    #e9e9e9 100%
  );
  animation: skeletonAnimation 3s infinite;
}
@keyframes skeletonAnimation {
  100% {
    transform: translateX(100%);
  }
}

.upload-page__image-item img {
  width: 100%;
  max-width: 300px;
  max-height: 280px;
  object-fit: contain;
}
@media (width < 900px) {
  .upload-page__image-item {
    height: 150px;
    max-width: 900px;
    padding: 2rem 0.25rem;
  }
  .upload-page__image-item img {
    max-width: 150px;
    max-height: 130px;
    margin-left: 0.5rem;
  }
  .upload-page__image-item-div {
    width: 150px;
    height: 130px;
    margin-left: 0.5rem;
  }
  .upload-page__trash-icon {
    font-size: 2rem;
  }
}
@media (width < 650px) {
  .upload-page__image-item {
    height: 120px;
    font-size: 1rem;
    max-width: 650px;
  }
  .upload-page__image-item img {
    max-width: 75px;
    max-height: 90px;
    margin-left: 0.5rem;
  }
  .upload-page__image-item-div {
    width: 75px;
    height: 90px;
    margin-left: 0.5rem;
  }
  .upload-page__trash-icon {
    font-size: 1rem;
    margin-right: 1rem;
  }
}
.upload-page__image-item-error-helper {
  background-color: rgb(176, 66, 66);
  color: white;
  font-weight: 400;
  border-radius: 1px;
  height: 200px;
  padding-left: 2.7rem;
}
.upload-page__image-item-error-x-icon {
  margin-right: 1rem;
  font-size: 3rem;
  cursor: pointer;
  color: red;
}
.upload-page__upload-form-side-bar-container {
  width: 100%;
  max-width: var(--siteContentsMidWidth);
  position: fixed;
  display: flex;
  justify-content: flex-start;
}
.upload-page__upload-form-side-bar-contents {
  position: absolute;
  left: -28px;
  top: 115px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.7rem;
}
.upload-page__upload-form-side-bar-container button {
  /* border-radius: 50%; */
  border: 3px solid rgb(58, 132, 230);
  /* border: 6px solid var(--greyBorder1); */
  width: 25px;
  height: 25px;
  font-size: 1rem;
  margin-left: 1.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(58, 132, 230);
}
.upload-page__upload-form-side-bar-add-icon {
  color: var(--offWhite);
  font-size: 1rem;
}
.upload-page__upload-form-side-bar-container a {
  border-radius: 12px;
  width: 80px;
  height: 80px;
  background-color: var(--greyBorder1);
}
.upload-page__upload-form-side-bar-container a img {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  object-fit: cover;
}
.upload-page__upload-form-side-bar-container a:focus {
  border-radius: 12px;
  border: 3px solid #fff;
  outline: 4px solid green;
  width: 85px;
  height: 85px;
  background-color: var(--greyBorder1);
}
/* .upload-page__upload-form-side-bar-container button img:focus {
  border: 2px solid green;
} */

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.dragOverCSS {
  background-color: rgb(205, 220, 252);
  border: 6px dashed rgb(55, 137, 209);
}
.upload-page__all-done-banner-container {
  z-index: 999;
  position: sticky;
  bottom: 0px;
  width: 100%;
  padding: 0.75rem 0;
  margin-bottom: 0rem;
  background-color: rgb(229, 238, 226);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 700;
  box-shadow: 0px -10px 15px -9px rgb(196, 196, 196);
}
.upload-page__all-done-banner-browse-button-container {
  width: 100%;
  max-width: var(--siteContentsMidWidth);
  height: auto;
  padding: 0;
  margin: auto;
  position: absolute;
  top: -30px;
  font-size: 1.25rem;
  /* padding-left: var(--sideSpacePadding); */
}
.upload-page__all-done-banner-upload-input {
  opacity: 0;
  font-size: 0px;
  cursor: pointer;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 87px;
  height: 100%;
  z-index: 999;
  background-color: rgb(89, 56, 56);
}
.upload-page__all-done-banner-container-button {
  padding: 0 0.76rem;
  border: 2px dashed rgb(186, 186, 186);
  border-bottom: none;
  background-color: rgb(234, 243, 255);
  color: rgb(60, 113, 226);
  border-top-right-radius: 5px;
}
.upload-page__all-done-banner-container-button-dragover {
  padding: 0 0.76rem;
  border-top-right-radius: 5px;
  background-color: rgba(186, 207, 251, 0.918);
  border: 2px dashed #3789d1;
  color: rgb(60, 113, 226);
  border-bottom: none;
  transition: 0.2s;
}
.upload-page__all-done-banner-container a {
  padding: 0.5rem 1rem;
  color: #fff;
  font-size: 0.9rem;
  font-weight: 500;
  background-color: rgb(78, 194, 32);
  border-radius: 4px;
  margin-left: 0.8rem;
}
@media (width > 1400px) {
  .upload-page__all-done-banner-container button {
    border-top-left-radius: 5px;
  }
}
@media (width < 900px) {
  .upload-page__all-done-banner-container {
    padding: 0.5rem 0;
    font-size: 0.9rem;
    /* font-weight: 500; */
    box-shadow: 0px -5px 4px -4.5px rgb(196, 196, 196);
  }
  .upload-page__all-done-banner-browse-button-container {
    top: -28px;
  }
  .upload-page__all-done-banner-container button {
    font-size: 1rem;
  }
  .upload-page__all-done-banner-upload-input {
    top: 4px;
    width: 75px;
    height: 24px;
  }
  .upload-page__all-done-banner-container a {
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
  }
}
@media (width < 650px) {
  .upload-page__all-done-banner-container {
    padding: 0.39rem 0;
    font-size: 0.8rem;
    bottom: -1px;
    /* font-weight: 500; */
    box-shadow: 0px -5px 4px -4.75px rgb(196, 196, 196);
  }
  .upload-page__all-done-banner-browse-button-container {
    top: -27px;
  }
  .upload-page__all-done-banner-container button {
    font-size: 0.7rem;
  }
  .upload-page__all-done-banner-upload-input {
    top: 10px;
    width: 60px;
    height: 17px;
  }
  .upload-page__all-done-banner-container a {
    padding: 0.25rem 0.5rem;
    font-size: 0.6rem;
  }
}

/* Site Tree CSS */

.site-tree__container {
  display: flex;
  gap: 0.4rem;
  align-items: center;
  font-size: 0.8rem;
  max-width: 1000px;
  margin: auto;
  margin-top: 1.3rem;
  margin-bottom: -1.25rem;
  padding-left: var(--sideSpacePadding);
}

@media (width < 650px) {
  .site-tree__container {
    margin-top: 0.75rem;
  }
}

.site-tree__past-link {
  color: var(--dark2);
}

.site-tree__past-link:hover {
  text-decoration: underline;
  color: var(--dark2);
}

/* Password Success Page CSS */

.password-success-page__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.35rem;
  height: 100vh;
}

.password-success-page__check-icon {
  font-size: 12rem;
  color: rgb(29, 152, 29);
  margin-bottom: -20px;
  margin-top: -50px;
}

/* Contact Page CSS */

.contact-page__form-container {
  margin: 2.75rem auto;
  padding: 2.5rem 2rem 4rem 2rem;
  max-width: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid rgb(229, 229, 229);
  border-radius: 3px;
}

@media (width < 650px) {
  .contact-page__form-container {
    margin-top: 2rem;
  }
}

@media (width < 550px) {
  .contact-page__form-container {
    border: none;
    margin-top: 1.75rem;
    padding-bottom: 10rem;
  }
}

.contact-page__form-container h1 {
  font-size: 1.625rem;
  font-weight: 1000;
  margin-bottom: 5.5rem;
}

.contact-page__input-container {
  margin-bottom: 2rem;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.contact-page__input-container label {
  display: block;
  font-size: 0.85rem;
  font-weight: 800;
  margin-bottom: 0.5rem;
}

.contact-page__input-container input {
  font-size: 1rem;
  width: 100%;
  height: 50px;
  border: 2px solid rgb(228, 228, 228);
  border-radius: 4px;
  padding: 0rem 0.5rem;
  position: relative;
  padding-right: 1rem;
}

.contact-page__input-container input:focus {
  border: 2px solid black;
}
.contact-page__input-container textarea {
  font-size: 1rem;
  width: 100% !important;
  height: 50px;
  border: 2px solid rgb(228, 228, 228);
  border-radius: 4px;
  padding: 0rem 0.5rem;
  position: relative;
  padding-right: 2.5rem;
}

.contact-page__input-container textarea:focus {
  border: 2px solid black;
}

.contact-page__eye-icon {
  position: absolute;
  right: 1rem;
  /* top: 47px; */
  bottom: 19px;
  font-size: 0.8rem;
  cursor: pointer;
}

.contact-page__input-container textarea {
  font-size: 1rem;
  width: 410px;
  min-height: 70px;
  height: 50px;
  border: 2px solid rgb(228, 228, 228);
  border-radius: 3px;
  padding: 0.2rem 0.5rem;
}

.contact-page__input-container textarea:focus {
  border: 2px solid black;
}

.contact-page__buttons-container {
  /* margin-top: 2.25rem; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1.05rem;
}

.contact-page__buttons-container-subcontainer {
  margin-top: 2.25rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1.05rem;
  width: fit-content;
}

.contact-page__form-container button {
  margin-top: 1.4rem;
  float: right;
  color: white;
  background-color: rgb(1, 1, 72);
  font-weight: 300;
  padding: 0.55rem 0.8rem;
  border-radius: 4px;
  transition: 0.2s;
}

.contact-page__form-container button:hover {
  background-color: rgb(6, 6, 41);
  transition: 0.2s;
}

.contact-page__cancel-button {
  /* margin-right: 1rem; */
  color: rgb(119, 119, 119) !important;
  background-color: var(--offWhite) !important;
  font-weight: 300;
  padding: 0.55rem 0.8rem;
  border-radius: 4px;
  transition: 0.2s;
  margin-top: 1.4rem;
  float: right;
}

.contact-page__send-button-loading-icon {
  color: var(--offWhite);
  display: inline;
  margin-left: 0.5rem;
}

.contact-page__send-button-success {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: green !important;
  background-color: lightgreen !important;
  /* animation: fadeOut2 4s; */
  /* animation-delay: 2s; */
  pointer-events: none;
}

.contact-page__send-button-fail {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: darkred !important;
  background-color: red !important;
  /* animation: fadeOut2 4s; */
  /* animation-delay: 2s; */
  pointer-events: none;
}

.contact-page__message {
  margin-top: 2.25rem;
  /* margin-bottom: -2rem; */
}

@media (width < 440px) {
  .contact-page__buttons-container-subcontainer {
    flex-direction: column-reverse;
    gap: 0;
  }
  .contact-page__buttons-container-subcontainer {
    margin-left: auto;
    margin-right: auto;
  }
  .contact-page__cancel-button {
    /* width: 125px; */
    width: 100%;
  }
  .contact-page__message {
    text-align: center;
  }
}

@keyframes fadeOut2 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Change Password Page CSS */

.change-password-page__form-container {
  margin: 2.75rem auto;
  padding: 2.5rem 2rem 4rem 2rem;
  max-width: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid rgb(229, 229, 229);
  border-radius: 3px;
}

@media (width < 650px) {
  .change-password-page__form-container {
    margin-top: 2rem;
  }
}

@media (width < 550px) {
  .change-password-page__form-container {
    border: none;
    margin-top: 1.75rem;
    padding-bottom: 1rem;
  }
}

.change-password-page__form-container h1 {
  font-size: 1.625rem;
  font-weight: 1000;
  margin-bottom: 3.75rem;
}

.change-password-page__input-container {
  margin-bottom: 2rem;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.change-password-page__input-container label {
  display: block;
  font-size: 0.85rem;
  font-weight: 800;
  margin-bottom: 0.5rem;
}

.change-password-page__input-container input {
  font-size: 1rem;
  width: 100%;
  height: 50px;
  border: 2px solid rgb(228, 228, 228);
  border-radius: 4px;
  padding: 0rem 0.5rem;
  position: relative;
  padding-right: 3.75rem;
}

.change-password-page__input-container input:focus {
  border: 2px solid black;
}
.change-password-page__input-container textarea {
  font-size: 1rem;
  width: 100% !important;
  height: 50px;
  border: 2px solid rgb(228, 228, 228);
  border-radius: 4px;
  padding: 0rem 0.5rem;
  position: relative;
  padding-right: 2.5rem;
}

.change-password-page__input-container textarea:focus {
  border: 2px solid black;
}
.change-password-page__eye-icon-container {
  /* background-color: rgba(0, 0, 0, 0.3); */
  height: 50px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0rem;
  /* top: 47px; */
  bottom: 0px;
  font-size: 0.8rem;
  cursor: pointer;
}
.change-password-page__eye-icon {
}
.change-password-page__eye-icon-last-container {
  /* background-color: rgba(0, 0, 0, 0.3); */
  height: 50px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0rem;
  /* top: 47px; */
  bottom: 85px;
  font-size: 0.8rem;
  cursor: pointer;
}
.change-password-page__eye-icon-last {
}

.change-password-page__input-container textarea {
  font-size: 1rem;
  width: 410px;
  min-height: 70px;
  height: 50px;
  border: 2px solid rgb(228, 228, 228);
  border-radius: 3px;
  padding: 0.2rem 0.5rem;
}

.change-password-page__input-container textarea:focus {
  border: 2px solid black;
}

.change-password-page__buttons-container {
  /* margin-top: 1.25rem; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1.05rem;
}

.change-password-page__buttons-container-subcontainer {
  margin-top: 1.35rem;
  /* display: flex; */
  justify-content: flex-end;
  align-items: center;
  gap: 1.05rem;
  width: fit-content;
}

.change-password-page__form-container button {
  margin-top: 1.4rem;
  float: right;
  color: white;
  background-color: rgb(1, 1, 72);
  font-weight: 300;
  padding: 0.55rem 0.8rem;
  border-radius: 4px;
  transition: 0.2s;
}

.change-password-page__form-container button:hover {
  background-color: rgb(6, 6, 41);
  transition: 0.2s;
}

.change-password-page__cancel-button {
  /* margin-right: 1rem; */
  color: rgb(119, 119, 119);
  background-color: var(--offWhite);
  margin-right: 1.05rem;
  margin-top: 1.4rem;
  float: right;
  font-weight: 300;
  padding: 0.55rem 0.8rem;
  border-radius: 4px;
  transition: 0.2s;
}
.change-password-page__cancel-button:hover {
  background-color: #f5f5f5;
  color: rgb(119, 119, 119);
}

@media (width < 380px) {
  .change-password-page__buttons-container-subcontainer {
    flex-direction: column;
    gap: 1rem;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    width: 100%;
  }
  .change-password-page__buttons-container-subcontainer button {
    width: 100%;
  }
  .change-password-page__cancel-button {
    width: 100%;
    margin: 0;
    text-align: center;
  }
  .change-password-page__eye-icon-last-container {
    bottom: 143px;
  }
}

@keyframes fadeOut2 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* SignUp CSS */

.signUpContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 45%;
  padding: 2rem;
  margin: auto;
  margin-top: 1.5rem;
}

.signUpContainer__heading {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  color: white;
}

.signUpContainer__p {
  font-size: 1rem;
  font-weight: 200;
  text-align: center;
  margin-bottom: 2rem;
  color: white;
}

.signUpContainer form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.signUpContainer input {
  background-color: rgb(241, 241, 241);
  border: none;
  border-radius: 4px;
  padding: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.signUpContainer input:focus {
  background-color: rgb(250, 250, 250);
  outline: 2px solid #3b5998;
}

.signUpContainer button {
  background-color: #3b5998;
  font-size: 1rem;
  padding: 0.5rem;
  font-weight: bold;
  color: white;
  border-radius: 3px;
}

.signUpContainer button:hover {
  background-color: #3f60a7;
}

/* toast css */

.toastInvisible {
  display: none;
}

.toastSuccess {
  width: 400px;
  max-height: 80px;
  padding: 1.15rem 1rem;
  background-color: green;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  color: white;
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translate(-200px, 0);
  z-index: 100;
}
.toastSuccess-modal {
  width: 400px;
  max-height: 80px;
  padding: 1.15rem 1rem;
  background-color: green;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  color: white;
  position: absolute;
  bottom: -2vh;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 100000000000000;
}
.toastSuccess-mobile {
  width: 100%;
  max-height: 58px;
  padding: 1.15rem 1rem;
  background-color: green;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  color: white;
  position: sticky;
  top: 136px;
  z-index: 100;
}
.toastSuccess-mobile-modal {
  width: 100%;
  max-height: 58px;
  padding: 1.15rem 1rem;
  background-color: green;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  color: white;
  position: absolute;
  bottom: -3vh;
  z-index: 1000;
}
.toastError-mobile {
  width: 100%;
  max-height: 58px;
  padding: 1.15rem 1rem;
  background-color: rgb(174, 61, 61);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  color: white;
  position: sticky;
  top: 136px;
  z-index: 100;
}
.toastError-mobile-modal {
  width: 100%;
  max-height: 58px;
  padding: 1.15rem 1rem;
  background-color: rgb(174, 61, 61);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  color: white;
  position: absolute;
  top: 136px;
  z-index: 1000;
}
@media (width < 650px) {
  .toastSuccess-mobile,
  .toastError-mobile {
    top: 126px;
  }
}
.toastError {
  width: 450px;
  max-height: 80px;
  padding: 1.15rem 1rem;
  background-color: rgb(174, 61, 61);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  color: white;
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translate(-225px, 0);
  z-index: 100;
}
.toastError-modal {
  width: 400px;
  max-height: 80px;
  padding: 1.15rem 1rem;
  background-color: rgb(174, 61, 61);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  color: white;
  position: absolute;
  top: 85vh;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 100000000000000;
}
@media (width < 480px) {
  .toastSuccess,
  .toastError {
    width: 100%;
    transform: translate(-50%, 0);
  }
  .toastSuccess button {
    font-size: 0.7rem;
  }
  .toastError button {
    font-size: 0.7rem;
  }
}
@media (width < 400px) {
  .toastSuccess,
  .toastError {
    font-size: 0.9rem;
  }
}

.reachedTheEndCont {
  font-size: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 160px;
}

.contactFormCont {
  margin: 2.75rem auto;
  padding: 2.5rem 2rem 4rem 2rem;
  width: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid rgb(229, 229, 229);
  border-radius: 3px;
}

.contactFormCont h1 {
  font-size: 1.625rem;
  font-weight: 1000;
  margin-bottom: 3.75rem;
}

.contactForm--InputBlock {
  margin-bottom: 2rem;
}

.contactForm--InputBlock label {
  display: block;
  font-size: 0.85rem;
  font-weight: 800;
  margin-bottom: 0.5rem;
}

.contactForm--InputBlock input {
  font-size: 1rem;
  width: 410px;
  height: 50px;
  border: 2px solid rgb(228, 228, 228);
  border-radius: 4px;
  padding: 0rem 0.5rem;
}

.contactForm--InputBlock input:focus {
  border: 2px solid black;
}

.contactForm--InputBlock textarea {
  font-size: 1rem;
  width: 410px;
  min-height: 70px;
  height: 50px;
  border: 2px solid rgb(228, 228, 228);
  border-radius: 3px;
  padding: 0.2rem 0.5rem;
}

.contactForm--InputBlock textarea:focus {
  border: 2px solid black;
}

.contactFormCont button {
  margin-top: 1.4rem;
  float: right;
  color: white;
  background-color: rgb(1, 1, 72);
  font-weight: 300;
  padding: 0.55rem 0.8rem;
  border-radius: 4px;
  transition: 0.2s;
}

.contactFormCont button:hover {
  background-color: rgb(6, 6, 41);
  transition: 0.2s;
}

/* Sign In Page CSS */

.sign-in-page__container {
}
.sign-in-page__contents-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin: auto;
  /* width: 100vw; */
  max-width: var(--siteContentsMaxWidth);
}
.sign-in-page__form-container {
  /* max-height: 590px; */
  /* height: 100vh; */
  width: 37.25rem;
  /* max-width: 47.25rem !important; */
  padding: 2.5rem 3rem 2.1rem var(--sideSpacePadding);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.sign-in-page__reset-password-link-sent-banner {
  background-color: rgb(12, 12, 48);
  color: var(--offWhite);
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 1.15rem;
}
.sign-in-page__form-container button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: auto;
  font-size: 1rem;
  width: 100%;
  height: 50px;
  border: 2px solid rgb(228, 228, 228);
  border-radius: 4px;
  padding: 0rem 0.5rem;
  margin-top: 1rem;
  font-weight: 500;
}
.sign-in-page__form-container button div {
  width: 110px;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  margin: auto;
}
.sign-in-page__form-container button img {
  /* width: 100%; */
  height: 50%;
}
.sign-in-page__oauth-button {
  color: #000;
}
.sign-in-page__oauth-button:hover {
  outline: 2px solid rgba(128, 128, 128, 0.222);
  box-shadow: 0px 0px 5px 5px rgba(128, 128, 128, 0.133);
  transition: box-shadow 0.01s;
}
.sign-in-page__or {
  width: 100%;
  margin-top: 1.25rem;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.sign-in-page__or-line {
  height: 1px;
  background-color: rgb(222, 222, 222);
  width: 100px;
}
.sign-in-page__invalid-username-or-password-alert-box {
  background-color: #b20000;
  color: white;
  padding: 1.7rem;
  margin-top: 1rem;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.sign-in-page__invalid-username-or-password-alert-box a {
  color: #fff;
  text-decoration: underline;
}
.sign-in-page__contents-container form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}
.sign-in-page__input-block {
  position: relative;
  margin-top: 1.25rem;
  width: 100%;
}

.sign-in-page__input-block label {
  display: block;
  font-size: 0.75rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.sign-in-page__input-block input {
  font-size: 1rem;
  width: 100%;
  height: 45px;
  border: 2px solid rgb(228, 228, 228);
  border-radius: 4px;
  padding: 0rem 3.75rem 0rem 0.75rem;
}
.sign-in-page__input-block input:focus {
  border: 2px solid black;
}
.sign-in-page__eye-icon-container {
  /* background-color: rgba(3, 3, 3, 0.3); */
  height: 45px;
  width: 40px;
  position: absolute;
  right: 0rem;
  /* top: 47px; */
  top: 26px;
  font-size: 0.8rem;
  cursor: pointer;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sign-in-page__eye-icon {
}
.sign-up-page__eye-icon-container {
  /* background-color: rgba(0, 0, 0, 0.3); */
  height: 45px;
  width: 40px;
  position: absolute;
  right: 0rem;
  /* top: 47px; */
  bottom: 0px;
  font-size: 0.8rem;
  cursor: pointer;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sign-up-page__eye-icon {
}
.sign-in-page__input-block-username-input-red {
  border: 2px solid rgb(151, 0, 0) !important;
  font-size: 1rem;
  width: 100%;
  height: 45px;
  border-radius: 4px;
  padding: 0rem 0.75rem;
}
.sign-in-page__input-block-username-input-red:focus {
  border: 2px solid rgb(151, 0, 0) !important;
}
.red-input-border {
  border: 2px solid rgb(173, 112, 112) !important;
}
.sign-in-page__already-exists-message {
  color: darkred;
  font-weight: 500;
  font-size: 0.75rem;
  display: inline;
  margin-left: 0.3rem;
}
.sign-in-page__input-block-please-fill-out-field-tooltip {
  position: absolute;
  top: 82px;
  left: 0;
  border: 1px solid var(--dark2);
  background-color: #fff;
  border-radius: 5px;
  width: 205px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.25rem;
  z-index: 10;
  box-shadow: 4px 4px 3px 0px rgba(131, 131, 131, 0.352);
  animation: tooltipFadeIn 0.3s;
}
@keyframes tooltipFadeIn {
  from {
    opacity: 0;
    width: 195px;
  }
  to {
    opacity: 1;
    width: 205px;
  }
}
.sign-in-page__input-block-tooltip-exclamation-icon {
  width: 15px;
  height: 15px;
  padding: 0.2rem;
  color: white;
}
.sign-in-page__input-block-please-fill-out-field-tooltip div {
  font-size: 0.9rem;
  /* font-weight: 500; */
}
.sign-in-page__input-block-please-fill-out-field-tooltip::after {
  content: "";
  position: absolute;
  border: 9px solid transparent;
  border-top-color: #ffffff;
  top: -17px;
  left: 10px;
  transform: rotate(180deg);
}
.sign-in-page__input-block-please-fill-out-field-tooltip::before {
  content: "";
  position: absolute;
  border: 9px solid transparent;
  border-top-color: var(--dark2);
  top: -18px;
  left: 10px;
  transform: rotate(180deg);
}
.sign-in-page__dont-have-account-container {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  font-size: 0.85rem;
}
@media (width < 410px) {
  .sign-in-page__dont-have-account-container {
    flex-direction: column;
    gap: 1rem;
  }
  /* .sign-in-page__eye-icon {
    bottom: 88.5px;
  } */
}
.sign-in-page__sign-in-button {
  font-size: 1rem;
  width: 100%;
  height: 50px;
  border: none !important;
  border-radius: 4px;
  padding: 0rem 0.5rem;
  margin-bottom: 1rem;
  font-weight: 500;
  background-color: var(--ctaYellow);
  color: black;
}
.sign-in-page__sign-in-button:hover {
  box-shadow: 0px 0px 10px 0.5px rgb(214, 187, 136);
  background-color: var(--ctaYellowDark);
  transition: 0.1s;
}
.sign-in-page__collage-image-container {
  height: 100vh;
  max-height: 1080px;
  max-width: 1000px;
  min-height: 660px;
  flex: 1;
  z-index: -10;
}
.sign-in-page__collage-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (width < 800px) {
  .sign-in-page__contents-container {
    flex-direction: column;
  }
  .sign-in-page__form-container {
    width: 100% !important;
    order: 2 !important;
  }
  .sign-in-page__collage-image-container {
    width: 100%;
    min-height: 0px;
  }
  .sign-in-page__collage-image {
    height: 100px;
  }
}
@media (height > 1000px) {
  .sign-in-page__form-container {
    margin-top: -2rem;
  }
}

/* About Us Page CSS */
.about-us-page__heading {
  text-align: center;
  margin: auto;
  height: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: var(--siteContentsMidWidth);
  padding: 0 var(--sideSpacePadding);
}
.about-us-page__contents-container {
  margin: auto;
  display: grid;
  grid-template-columns: 2fr 1fr;
  max-width: var(--siteContentsMidWidth);
  margin-bottom: 3rem;
  padding: 0 55px;
}
.about-us-page__text-container {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  max-height: 100%;
}
.about-us-page__text-container p {
  font-size: 1.2rem;
  padding-right: 7rem;
}
.about-us-page__img-container {
  background-color: #e4a325;
  overflow: hidden;
}
.about-us-page__img-container img {
  max-width: calc(var(--siteContentsMidWidth) / 3);
  /* display: block; */
  height: 100%;
  object-fit: cover;
}
@media (width < 950px) {
  .about-us-page__img-container img {
    max-width: 400px;
  }
}
@media (width < 750px) {
  .about-us-page__contents-container {
    padding: 0 27.5px;
  }
  .about-us-page__img-container img {
    max-width: 300px;
  }
}
@media (width < 650px) {
  .about-us-page__contents-container {
    display: flex;
    flex-direction: column-reverse;
    gap: 2rem;
  }
  .about-us-page__text-container p {
    padding-right: 0;
  }
  .about-us-page__img-container {
    height: 100px;
    margin-bottom: 1rem;
    overflow: hidden;
  }
  .about-us-page__img-container img {
    object-position: 0px -635px;
    object-fit: cover;
    height: 850%;
    max-width: 200%;
  }
  .about-us-page__heading {
    height: 60px;
    margin-bottom: 1rem;
  }
}
@media (width < 450px) {
  .about-us-page__img-container {
    height: 40px;
  }
  .about-us-page__img-container img {
    object-position: 0px -520px;
    max-width: 450px;
    height: 550px;
    width: 450px;
  }
}

/* Forgot Password Send Link Page CSS */

.forgot-password-send-link-page__container {
}
.forgot-password-send-link-page__contents-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin: auto;
  /* width: 100vw; */
  max-width: var(--siteContentsMaxWidth);
}
.forgot-password-send-link-page__form-container {
  /* max-height: 590px; */
  /* height: 100vh; */
  width: 37.25rem;
  /* max-width: 47.25rem !important; */
  padding: 2.5rem 3rem 2.1rem var(--sideSpacePadding);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: -10rem;
}
@media (width < 800px) {
  .forgot-password-send-link-page__form-container p {
    margin-top: 0.7rem;
  }
}
.forgot-password-send-link-page__heading {
  /* fontSize: "2rem", color: "#f5c000", fontWeight: "500" */
  font-size: 2rem;
  color: var(--ctaYellow);
  font-weight: 500;
  margin: 0.45rem 0 1.5rem 0;
}
@media (width < 380px) {
  .forgot-password-send-link-page__heading {
    font-size: 1.95rem;
    margin: 0.7rem 0 1.2rem 0;
  }
}
.forgot-password-send-link-page__form-container button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: auto;
  font-size: 1rem;
  width: 100%;
  height: 50px;
  border: 2px solid rgb(228, 228, 228);
  border-radius: 4px;
  padding: 0rem 0.5rem;
  margin-top: 1rem;
  font-weight: 500;
}
.forgot-password-send-link-page__form-container button div {
  width: 110px;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  margin: auto;
}
.forgot-password-send-link-page__form-container button img {
  /* width: 100%; */
  height: 50%;
}
.forgot-password-send-link-page__oauth-button {
}
.forgot-password-send-link-page__oauth-button:hover {
  outline: 2px solid rgba(128, 128, 128, 0.222);
  box-shadow: 0px 0px 5px 5px rgba(128, 128, 128, 0.133);
  transition: box-shadow 0.01s;
}
.forgot-password-send-link-page__or {
  width: 100%;
  margin-top: 1.25rem;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.forgot-password-send-link-page__or-line {
  height: 1px;
  background-color: rgb(222, 222, 222);
  width: 100px;
}
.forgot-password-send-link-page__invalid-username-or-password-alert-box {
  background-color: red;
  color: white;
  padding: 1.7rem;
  margin-top: 1rem;
  width: 100%;
  text-align: center;
}
.forgot-password-send-link-page__contents-container form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}
.forgot-password-send-link-page__input-block {
  position: relative;
  margin-top: 1.25rem;
  width: 100%;
}

.forgot-password-send-link-page__input-block label {
  display: block;
  font-size: 0.75rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.forgot-password-send-link-page__input-block input {
  font-size: 1rem;
  width: 100%;
  height: 45px;
  border: 2px solid rgb(228, 228, 228);
  border-radius: 4px;
  padding: 0rem 0.75rem;
}
.forgot-password-send-link-page__input-block input:focus {
  border: 2px solid black;
}
.forgot-password-send-link-page__input-block-username-input-red {
  border: 2px solid rgb(151, 0, 0) !important;
  font-size: 1rem;
  width: 100%;
  height: 45px;
  border-radius: 4px;
  padding: 0rem 0.75rem;
}
.forgot-password-send-link-page__input-block-username-input-red:focus {
  border: 2px solid rgb(151, 0, 0) !important;
}
.red-input-border {
  border: 2px solid rgb(173, 112, 112) !important;
}
.forgot-password-send-link-page__already-exists-message {
  color: darkred;
  font-weight: 500;
  font-size: 0.75rem;
  display: inline;
  margin-left: 0.3rem;
}
.forgot-password-send-link-page__input-block-please-fill-out-field-tooltip {
  position: absolute;
  top: 82px;
  left: 0;
  border: 1px solid var(--dark2);
  background-color: #fff;
  border-radius: 5px;
  width: 205px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.25rem;
  z-index: 10;
  box-shadow: 4px 4px 3px 0px rgba(131, 131, 131, 0.352);
  animation: tooltipFadeIn 0.3s;
}
@keyframes tooltipFadeIn {
  from {
    opacity: 0;
    width: 195px;
  }
  to {
    opacity: 1;
    width: 205px;
  }
}
.forgot-password-send-link-page__input-block-tooltip-exclamation-icon {
  width: 15px;
  height: 15px;
  padding: 0.2rem;
  color: white;
}
.forgot-password-send-link-page__input-block-please-fill-out-field-tooltip div {
  font-size: 0.9rem;
  /* font-weight: 500; */
}
.forgot-password-send-link-page__input-block-please-fill-out-field-tooltip::after {
  content: "";
  position: absolute;
  border: 9px solid transparent;
  border-top-color: #ffffff;
  top: -17px;
  left: 10px;
  transform: rotate(180deg);
}
.forgot-password-send-link-page__input-block-please-fill-out-field-tooltip::before {
  content: "";
  position: absolute;
  border: 9px solid transparent;
  border-top-color: var(--dark2);
  top: -18px;
  left: 10px;
  transform: rotate(180deg);
}
.forgot-password-send-link-page__sign-in-button {
  font-size: 1rem;
  width: 100%;
  height: 50px;
  border: none !important;
  border-radius: 4px;
  padding: 0rem 0.5rem;
  margin-bottom: 1rem;
  font-weight: 500;
  background-color: var(--ctaYellow);
  color: black;
}
.forgot-password-send-link-page__sign-in-button:hover {
  box-shadow: 0px 0px 10px 0.5px rgb(214, 187, 136);
  background-color: var(--ctaYellowDark);
  transition: 0.1s;
}
.forgot-password-send-link-page__collage-image-container {
  height: 100vh;
  max-height: 1080px;
  max-width: 1000px;
  min-height: 660px;
  flex: 1;
  z-index: -10;
}
.forgot-password-send-link-page__collage-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (width < 650px) {
}

@media (width < 800px) {
  .forgot-password-send-link-page__contents-container {
    flex-direction: column;
  }
  .forgot-password-send-link-page__form-container {
    width: 100% !important;
    order: 2 !important;
    margin-top: 0;
    margin-bottom: 10rem;
  }
  .forgot-password-send-link-page__collage-image-container {
    width: 100%;
    min-height: 0px;
  }
  .forgot-password-send-link-page__collage-image {
    height: 100px;
  }
}
@media (height > 1000px) {
  .forgot-password-send-link-page__form-container {
    margin-top: -2rem;
  }
}

/* Yes Or No Modal CSS */

.yes-or-no-modal__container {
  position: absolute;
  z-index: 9999999;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}
.yes-or-no-modal__background {
  background-color: rgba(0, 0, 0, 0.8);
}
.yes-or-no-modal__box {
  width: 500px;
  height: 250px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.25rem;
  border-radius: 10px;
  background-color: var(--offWhite);
  text-align: center;
  font-weight: 500;
  font-size: 1.15rem;
}
.yes-or-no-modal__buttons-container {
  display: flex;
  gap: 1.5rem;
}
.yes-or-no-modal__buttons-container button {
  background-color: #3b5998;
  color: white;
  font-weight: 500;
  font-size: 1.1rem;
  padding: 0.75rem 3rem;
  /* width: 4rem; */
  /* height: 2rem; */
  border-radius: 3px;
}

/* Image Select Modal CSS */

.image-select-modal__container {
  z-index: 100000000000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding-bottom: 1.75rem;
  overflow-y: auto;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-direction: column; */
  background-color: rgba(0, 0, 0, 0.9);
  padding-top: 1.75rem;
}
/* .image-select-modal__container::-webkit-scrollbar {
  display: none;
} */
.image-select-modal__background {
  z-index: 99;
  width: 100%;
  /* height: 150%; */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: rgba(0, 0, 0, 0.9); */
}
.image-select-modal__contents-container {
  z-index: 100;
  /* margin: auto; */
  display: block;
  position: relative;
  width: 75vw;
  background-color: #fff;
  border-radius: 10px;
  padding: 1.5rem;
  /* margin-top: 1.75rem; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 90vh;
}
@media (width < 1920px) {
  .image-select-modal__contents-container {
    margin: auto;
  }
}
@media (width < 650px) {
  .image-select-modal__contents-container {
    min-height: 0;
  }
}

.image-select-modal__x-icon {
  color: #fff;
  font-size: 1.6rem;
  position: absolute;
  top: -0.3rem;
  left: -2.5rem;
  transition: 0.2s;
}
.image-select-modal__x-icon:hover {
  color: var(--dark5);
  transition: 0.2s;
}
.image-select-modal__left-arrow-icon {
  color: #fff;
  font-size: 2.7rem;
  position: absolute;
  top: 300px;
  left: -70px;
  transition: 0.2s;
}
.image-select-modal__left-arrow-icon:hover {
  color: var(--dark5);
  transition: 0.2s;
}
.image-select-modal__right-arrow-icon {
  color: #fff;
  font-size: 2.7rem;
  position: absolute;
  top: 300px;
  right: -70px;
  transition: 0.2s;
}
.image-select-modal__right-arrow-icon:hover {
  color: var(--dark5);
  transition: 0.2s;
}
@media (width < 650px) {
  .image-select-modal__container {
    padding-top: 0;
  }
  .image-select-modal__contents-container {
    width: 100vw;
    margin-top: 4rem;
    border-radius: 0;
    padding: 0.95rem 0.5rem 1.5rem 0.5rem;
  }
  .image-select-modal__x-icon {
    color: #ffffff;
    font-size: 2rem;
    position: absolute;
    top: -50px;
    left: 20px;
    transition: 0.2s;
  }
  .image-select-modal__left-arrow-icon {
    top: 30vh;
    left: 20px;
    color: rgba(255, 255, 255, 0.329);
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    padding: 0.25rem 0.445rem;
  }
  .image-select-modal__right-arrow-icon {
    top: 30vh;
    right: 20px;
    color: rgba(255, 255, 255, 0.329);
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    padding: 0.25rem 0.445rem;
  }
  .image-select-modal__left-arrow-icon:hover,
  .image-select-modal__right-arrow-icon:hover {
    color: rgba(255, 255, 255, 0.329);
  }
  .image-select-modal__left-arrow-icon:active,
  .image-select-modal__right-arrow-icon:active {
    color: rgba(255, 255, 255, 0.329);
    background-color: rgba(0, 0, 0, 0.4);
    transition: 0.2s;
  }
}
.image-select-modal__top-bar-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.3rem;
  /* height: 80px; */
  flex-wrap: wrap;
  gap: 1.5rem;
}
/* @media (width < 375px) {
  .image-view-page__top-bar-container {
    height: 110px;
    top: 70px;
  }
  .image-view-page__top-bar-height-margin {
    height: 110px;
  }
} */
.image-select-modal__image-author-link-container {
  cursor: pointer;
  text-decoration: none;
  color: var(--dark2);
  font-weight: 700;
  font-size: 1rem;
  display: flex;
  align-items: flex-start;
  gap: 0.425rem;
}
.image-select-modal__image-author-pfp-div {
  position: relative;
}
.image-select-modal__image-author-pfp {
  border-radius: 50%;
  width: 48px;
  height: 48px;
  object-fit: cover;
}
.image-select-modal__image-author-pfp::after {
  opacity: 0;
  background-color: #000;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  content: "";
  z-index: 10000000000000000;
  transition: 0.25s;
}
.image-select-modal__image-author-pfp-div:hover
  .image-select-modal__image-author-pfp::after {
  opacity: 0.4 !important;
  transition: 0.25s;
}
.image-select-modal__image-author-pfp {
  border-radius: 50%;
  width: 48px;
  height: 48px;
  object-fit: cover;
}
.image-select-modal__image-author-name {
  color: var(--dark2);
}
.image-select-modal__image-author-name:hover {
  color: var(--dark2);
}
.image-select-modal__top-bar-buttons-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.image-select-modal__main-like-button {
  display: flex;
  outline: 1px var(--greyBorder1) solid;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  gap: 0.5rem;
  font-size: 1rem;
  transition: outline 0.2s;
}
.image-select-modal__main-like-button:hover {
  outline: 1px var(--dark3) solid;
  transition: outline 0.2s;
}
.image-select-modal__main-like-button-icon-unliked {
  border-radius: 10px;
  pointer-events: none;
  z-index: 100;
  color: #b4b4b4;
}
.image-select-modal__main-like-button-icon-liked {
  color: red;
  z-index: 105;
  position: relative;
  animation: image-view-page__main-like-button-animation-big 0.4s;
}
@keyframes image-view-page__main-like-button-animation-big {
  from {
    transform: scale(1.2);
    color: rgb(234, 1, 1);
  }
  to {
    transform: scale(1);
    color: red;
  }
}
@keyframes image-view-page__main-like-button-animation-small {
  from {
    transform: scale(1.3);
    color: rgb(234, 1, 1);
  }
  to {
    transform: scale(1);
    color: red;
  }
}
.image-select-modal__download-button {
  display: flex;
  background-color: rgb(13, 149, 127);
  color: white;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  height: 40px;
  padding: 0.5rem 1rem;
  gap: 0.2rem;
  font-size: 1rem;
  font-weight: 500;
  transition: background-color 0.1s;
}
.image-select-modal__download-button:hover {
  color: #fff;
  background-color: rgb(16, 138, 117);
  transition: background-color 0.2s;
}
@media (width < 650px) {
  .image-select-modal__main-like-button {
    font-size: 1rem;
  }
  .image-select-modal__main-like-button-icon-liked {
    animation: image-view-page__main-like-button-animation-small 0.4s;
  }
  .image-select-modal__main-like-button-text {
    display: none;
  }
  /* .image-select-modal__download-button {
    font-size: 0.8rem;
    padding: 0.25rem 0.75rem;
  } */
}
.image-select-modal__edit-btn {
  background-color: #d9d9d9;
  color: var(--dark2);
  font-size: 1.3rem;
  padding: 0.5rem 1rem;
  display: flex;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.image-select-modal__edit-btn:hover {
  background-color: #d2d2d2;
  color: #272e35;
}
.image-select-modal__edit-btn-active {
  background-color: #3c3e42;
  color: #dcdcdc;
  font-size: 1.3rem;
  padding: 0.5rem 1rem;
  display: flex;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.image-select-modal__delete-btn {
  background-color: #b20000;
  color: #f6f6f6;
  font-size: 1.3rem;
  padding: 0.5rem 1rem;
  display: flex;
  height: 44.79px;
  justify-content: center;
  height: 40px;
  align-items: center;
  border-radius: 5px;
}
.image-select-modal__delete-btn:hover {
  background-color: #a30000;
  color: #eeeeee;
}
.image-select-modal__delete-yes-or-no {
  background-color: #b20000;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  padding: 1rem;
  max-width: 240px;
  position: absolute;
  top: 90px;
  right: 24px;
}
.image-select-modal__delete-yes-or-no::before {
  border: 17px solid rgba(0, 0, 0, 0);
  border-top: 27px solid #b20000;
  content: "";
  transform: rotate(180deg);
  top: -32px;
  right: 12px;
  position: absolute;
}
.image-select-modal__delete-yes-or-no-btns {
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
}
.image-select-modal__delete-yes-or-no-btns button {
  background-color: #fff;
  color: #b20000;
  padding: 0.5rem 1rem;
  border-radius: 4px;
}
.image-select-modal__delete-yes-or-no-btns button:hover {
  background-color: #ececec;
  color: #a30000;
}
.image-select-modal__img-container {
  /* width: 100%; */
  max-width: 100%;
  /* min-height: 500px; */
  max-height: 500px;
  margin: auto;
  display: flex;
  justify-content: center;
  overflow: hidden;
  /* background-color: rebeccapurple; */
}
.image-select-modal__img-height-spacing {
  height: 500px;
}
.image-select-modal__img-zoomed-out {
  /* margin: auto; */
  max-height: 500px;
  max-width: 100%;
  object-fit: contain;
  cursor: zoom-in;
}
.image-select-modal__img-zoomed-in {
  transform: scale(3);
  transition: transform 0.1s;
  /* transform-origin: center center; */
  /* transform: translate3d(250px, 200px, 0); */
  /* margin: auto; */
  max-height: 1500px;
  max-width: 100%;
  object-fit: contain;
  cursor: zoom-out;
}
@media (width < 900px) {
  .image-select-modal__img-container {
    max-height: 100%;
    height: 100%;
  }
  .image-select-modal__img-container img {
    max-height: 100%;
    max-width: 100%;
  }
  .image-select-modal__img-height-spacing {
    display: none;
  }
}
.image-select-modal__img-info-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
  margin-top: 1.25rem;
}
.image-select-modal__img-info-container form {
  /* background-color: red; */
  margin: 0;
  /* margin-top: 2.8rem; */
  padding: 0;
}
.image-select-modal__img-info-container form input {
  border: 1px solid var(--dark5);
  border-radius: 4px;
  background-color: #ecf7ff;
}
.image-select-modal__img-info-container form input:focus {
  background-color: #ffffff;
}
@media (width < 650px) {
  .image-select-modal__img-info-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
}
.image-select-modal__img-title {
  width: 100%;
  font-size: 2rem;
  font-weight: 500;
  word-wrap: break-word;
  line-height: 1.5;
}
.image-select-modal__img-title-input {
  border-radius: 2px;
  color: #000;
  width: 100%;
  font-size: 2rem;
  font-weight: 500;
  padding: 0.25rem 0.5rem;
  word-wrap: break-word;
  line-height: 1.5;
}
.image-select-modal__img-title-input::placeholder {
  color: var(--dark4);
  width: 100%;
  font-size: 2rem;
  font-weight: 500;
  word-wrap: break-word;
  line-height: 1.5;
}
.image-select-modal__img-title-input-red {
  border-radius: 2px;
  outline: 2px solid rgb(151, 0, 0) !important;
  color: #000;
  width: 100%;
  font-size: 2rem;
  font-weight: 500;
  padding: 0.25rem 0.5rem;
  word-wrap: break-word;
  line-height: 1.5;
}
.image-select-modal__img-title-input-red:focus {
  outline: 2px solid rgb(151, 0, 0) !important;
  /* outline: none; */
}
.image-select-modal__img-description {
  color: var(--dark4);
  font-weight: 500;
  font-style: italic;
  margin-top: 0.25rem;
  margin-bottom: 2rem;
  line-height: 1;
  font-size: 1rem;
  width: 100%;
  word-wrap: break-word;
}
.image-select-modal__img-description-input {
  border-radius: 2px;
  color: var(--dark4);
  font-weight: 500;
  font-style: italic;
  margin-top: 2rem;
  padding: 0.25rem 0.5rem;
  width: 100%;
  word-wrap: break-word;
}
.image-select-modal__img-description-input::placeholder {
  color: var(--dark4);
  font-weight: 500;
  font-style: italic;
  width: 100%;
  word-wrap: break-word;
}
.image-select-modal__description-char-count {
  font-size: 0.5rem;
  line-height: 1;
  margin-top: 0.25rem;
  margin-left: 0.4rem;
}
@media (width < 650px) {
  .image-select-modal__img-title,
  .image-select-modal__img-description {
    max-width: 100%;
    margin-bottom: 0;
  }
  .image-select-modal__img-description {
    margin-bottom: 1.8rem;
  }
}
.image-select-modal__img-tags-input-container {
}
.image-select-modal__img-tags-input-and-btns-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}
.image-select-modal__img-tags-input {
  border-radius: 2px;
  padding: 0.25rem 0.5rem;
}
.image-select-modal__img-tags-input-red {
  border-radius: 2px;
  outline: 2px solid rgb(151, 0, 0) !important;
  padding: 0.25rem 0.5rem;
}
.image-select-modal__img-tags-input-red:focus {
  outline: 2px solid rgb(151, 0, 0);
  /* outline: none; */
  padding: 0.25rem 0.5rem;
}
.image-select-modal__img-tags-input-container-btns-container {
  display: flex;
  gap: 0.75rem;
  flex-wrap: wrap;
}
.image-select-modal__img-tags-input-container-btns-container button {
  border: 1px solid #000;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
  padding: 0.25rem 0.5rem;
  height: 33.33px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-select-modal__img-tags-container {
  width: 100%;
  max-height: 80px;
  margin-top: 2.3rem;
  display: flex;
  justify-content: flex-start;
  gap: 0.45rem;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.image-select-modal__img-tags-container::-webkit-scrollbar {
  display: none;
}
.image-select-modal__img-tags-overflowArrowLeft {
  z-index: 999999;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 37%;
  left: -9px;
  padding: 0.5rem;
  padding-right: 3.5rem;
  padding-left: 1rem;
  height: 60px;
  background: linear-gradient(
    90deg,
    rgb(255, 255, 255) 30%,
    rgba(0, 212, 255, 0) 90%
  );
  animation: fadeIn 0.2s;
}
.image-select-modal__img-tags-overflowArrowRight {
  z-index: 999999999999;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 37%;
  right: -11px;
  padding: 0.5rem;
  padding-left: 5rem;

  height: 60px;
  background: linear-gradient(
    270deg,
    rgb(255, 255, 255) 30%,
    rgba(0, 212, 255, 0) 50%
  );
  animation: fadeIn 0.2s;
}
@media (width < 900px) {
  .image-select-modal__img-tags-overflowArrowRight {
    right: 0px;
  }
}
.image-select-modal__img-tags-arrowIcon {
  font-size: 1rem;
  color: rgb(85, 85, 85);
}
.image-select-modal__img-tags-heading {
}
.image-select-modal__img-tags-list {
  width: 100%;
}
.image-select-modal__img-tags-list ul {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 0.5rem;
}
.image-select-modal__img-tags-list a {
  color: #000;
  padding: 1.45rem 1.5rem;
  /* background-color: red; */
}
.image-select-modal__img-tags-list ul li {
  border: 2px solid #cccccc;
  font-weight: 500;
  font-size: 1.25rem;
  border-radius: 4px;
  /* padding: 0.45rem 0rem; */
  height: 48px;
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.image-select-modal__img-tags-list ul li:hover {
  background-color: var(--ctaYellow);
  border: 2px solid var(--ctaYellow);
  transition: 0.2s;
}

/* Image View Page CSS */

.image-view-page__container {
  max-width: var(--siteContentsMidWidth);
  /* display: flex;
  justify-content: center;
  align-items: center; */
  margin: auto;
  padding: 0 var(--imgGalleryMarginBetweenImages);
}
.image-view-page__top-bar-container {
  background-color: #ffffff;
  display: flex;
  min-height: 88px;
  justify-content: center;
  position: sticky;
  top: 80px;
  width: 100vw;
  z-index: 10;
  margin: auto;
  padding: 1rem 0;
}
.image-view-page__top-bar-height-margin {
  height: 0px;
  color: #fff;
}
.image-view-page__top-bar-contents {
  padding: 0 calc(var(--sideSpacePadding) + 0rem) 0
    calc(var(--sideSpacePadding) - 0.3rem);
  width: 100vw;
  max-width: var(--siteContentsMidWidth);
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  align-items: center;
  justify-content: space-between;
}
@media (width < 650px) {
  .image-view-page__top-bar-container {
    min-height: 64px;
    position: fixed;
    top: 70px;
  }
  .image-view-page__top-bar-height-margin {
    height: 64px;
  }
}
@media (width < 900px) {
  .image-view-page__top-bar-contents {
    padding: 0 calc(var(--sideSpacePadding) - 0.15rem) 0
      calc(var(--sideSpacePadding) - 0.3rem);
  }
}

@media (width < 421px) {
  .image-view-page__top-bar-container {
    /* min-height: 110px; */
    /* top: 80px; */
  }
  .image-view-page__top-bar-height-margin {
    height: 95px;
  }
  /* .image-view-page__top-bar-contents {
    padding-top: 0.75rem;
    padding-bottom: 1rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
  } */
}
@media (width < 324px) {
  .image-view-page__top-bar-height-margin {
    height: 130px;
  }
}
.image-view-page__image-author-link-container {
  cursor: pointer;
  text-decoration: none;
  color: var(--dark2);
  font-weight: 700;
  font-size: 1rem;
  display: flex;
  align-items: flex-start;
  gap: 0.425rem;
}
.image-view-page__image-author-pfp-div {
  position: relative;
}
.image-view-page__image-author-pfp {
  border-radius: 50%;
  width: 48px;
  height: 48px;
  object-fit: cover;
}
.image-view-page__image-author-pfp::after {
  opacity: 0;
  background-color: #000;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  content: "";
  z-index: 10000000000000000;
  transition: 0.25s;
}
.image-view-page__image-author-pfp-div:hover
  .image-view-page__image-author-pfp::after {
  opacity: 0.4 !important;
  transition: 0.25s;
}
.image-view-page__image-author-pfp {
  border-radius: 50%;
  width: 48px;
  height: 48px;
}
.image-view-page__image-author-name {
  color: var(--dark2);
}
.image-view-page__image-author-name:hover {
  color: var(--dark2);
}
/* @media (width < 650px) {
  .image-view-page__image-author-pfp {
    width: 35px;
    height: 35px;
  }
  .image-view-page__image-author-pfp::after {
    width: 35px;
    height: 35px;
  }
  .image-view-page__image-author-link-container {
    font-size: 0.75rem;
  }
} */
.image-view-page__top-bar-buttons-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.image-view-page__main-like-button {
  display: flex;
  outline: 1px var(--greyBorder1) solid;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  height: 40px;
  padding: 0.5rem 1rem;
  gap: 0.5rem;
  font-size: 1rem;
  transition: outline 0.2s;
}
.image-view-page__main-like-button:hover {
  outline: 1px var(--dark3) solid;
  transition: outline 0.2s;
}
.image-view-page__main-like-button-icon-unliked {
  border-radius: 10px;
  pointer-events: none;
  z-index: 100;
  color: #b4b4b4;
}
.image-view-page__main-like-button-icon-liked {
  color: red;
  z-index: 105;
  position: relative;
  animation: image-view-page__main-like-button-animation-big 0.4s;
}
@keyframes image-view-page__main-like-button-animation-big {
  from {
    transform: scale(1.2);
    color: rgb(234, 1, 1);
  }
  to {
    transform: scale(1);
    color: red;
  }
}
@keyframes image-view-page__main-like-button-animation-small {
  from {
    transform: scale(1.3);
    color: rgb(234, 1, 1);
  }
  to {
    transform: scale(1);
    color: red;
  }
}
.image-view-page__main-like-button-text {
  color: #000;
}
.image-view-page__download-button {
  display: flex;
  background-color: rgb(13, 149, 127);
  color: white;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  height: 40px;
  padding: 0.5rem 1rem;
  gap: 0.2rem;
  font-size: 1rem;
  font-weight: 500;
  transition: background-color 0.1s;
}
.image-view-page__download-button:hover {
  color: #fff;
  background-color: rgb(16, 138, 117);
  transition: background-color 0.2s;
}
.image-view-page__edit-btn {
  background-color: #d9d9d9;
  color: var(--dark2);
  font-size: 1.3rem;
  padding: 0.5rem 1rem;
  display: flex;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.image-view-page__edit-btn:hover {
  background-color: #d2d2d2;
  color: #272e35;
}
.image-view-page__edit-btn-active {
  background-color: #3c3e42;
  color: #dcdcdc;
  font-size: 1.3rem;
  padding: 0.5rem 1rem;
  display: flex;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.image-view-page__delete-btn {
  background-color: #b20000;
  color: #f6f6f6;
  font-size: 1.3rem;
  padding: 0.5rem 1rem;
  display: flex;
  height: 44.79px;
  justify-content: center;
  height: 40px;
  align-items: center;
  border-radius: 5px;
  position: relative;
}
.image-view-page__delete-btn:hover {
  background-color: #ad0000;
  color: #eeeeee;
}
.image-view-page__delete-yes-or-no {
  background-color: #b20000;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  padding: 1rem;
  max-width: 240px;
  width: 240px;
  text-align: left;
  position: absolute;
  top: 60px;
  right: 0px;
  font-size: 1rem;
}
.image-view-page__delete-yes-or-no::before {
  border: 17px solid rgba(0, 0, 0, 0);
  border-top: 27px solid #b20000;
  content: "";
  transform: rotate(180deg);
  top: -32px;
  right: 12px;
  position: absolute;
}
.image-view-page__delete-yes-or-no-btns {
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
}
.image-view-page__delete-yes-or-no-btns button {
  background-color: #fff;
  color: #b20000;
  padding: 0.5rem 1rem;
  border-radius: 4px;
}
.image-view-page__delete-yes-or-no-btns button:hover {
  background-color: #ececec;
  color: #a30000;
}
/* @media (width < 650px) {
  .image-view-page__main-like-button {
    font-size: 0.8rem;
  }
  .image-view-page__main-like-button-icon-liked {
    animation: image-view-page__main-like-button-animation-small 0.4s;
  }
  .image-view-page__main-like-button-text {
    display: none;
  }
  .image-view-page__download-button {
    font-size: 0.8rem;
    padding: 0.25rem 0.75rem;
  }
  .image-view-page__edit-btn {
    font-size: 0.8rem;
  }
  .image-view-page__delete-btn {
    font-size: 0.8rem;
  }
} */
.image-view-page__img-container {
  width: 100%;
  height: 500px;
  max-height: 500px;
  margin: auto;
  display: flex;
  overflow: hidden;
}
@media (width < 650px) {
  .image-view-page__img-container {
    margin-top: 0.25rem;
  }
}
.image-view-page__img-zoomed-out {
  margin: auto;
  max-height: 500px;
  max-width: 80%;
  object-fit: contain;
  cursor: zoom-in;
}
.image-view-page__img-zoomed-in {
  transform: scale(3);
  transition: transform 0.1s;
  margin: auto;
  max-height: 500px;
  max-width: 80%;
  object-fit: cover;
  cursor: zoom-out;
}
@media (width < 900px) {
  .image-view-page__img-container {
    max-height: 400px;
    height: 400px;
  }
  .image-view-page__img-container img {
    max-height: 100%;
    max-width: 100%;
  }
}
.image-view-page__img-info-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
  margin-top: 1rem;
  max-width: var(--siteContentsMidWidth);
  padding: 0 var(--imgGalleryMarginBetweenImages);
}
@media (width < 900px) {
  .image-view-page__img-info-container {
    padding: 0;
  }
}
@media (width < 650px) {
  .image-view-page__img-info-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
}
.image-view-page__img-info-container form {
  /* background-color: red; */
  margin: 0;
  margin-top: -1.3rem;
  padding: 0;
  margin-bottom: 7rem;
}
.image-view-page__img-info-container form input {
  border: 1px solid var(--dark5);
  border-radius: 4px;
  background-color: #ecf7ff;
}
.image-view-page__img-info-container form input:focus {
  background-color: #ffffff;
}
.image-view-page__img-title {
  width: 100%;
  font-size: 2rem;
  font-weight: 500;
  word-wrap: break-word;
  max-width: 50%;
  line-height: 1.5;
}
.image-view-page__img-title-input {
  border-radius: 2px;
  color: #000;
  width: 100%;
  font-size: 2rem;
  font-weight: 500;
  padding: 0.25rem 0.5rem;
  word-wrap: break-word;
  line-height: 1.5;
}
.image-view-page__img-title-input::placeholder {
  color: var(--dark4);
  width: 100%;
  font-size: 2rem;
  font-weight: 500;
  word-wrap: break-word;
  line-height: 1.5;
}
.image-view-page__img-title-input-red {
  border-radius: 2px;
  outline: 2px solid rgb(151, 0, 0) !important;
  color: #000;
  width: 100%;
  font-size: 2rem;
  font-weight: 500;
  padding: 0.25rem 0.5rem;
  word-wrap: break-word;
  line-height: 1.5;
}
.image-view-page__img-title-input-red:focus {
  outline: 2px solid rgb(151, 0, 0) !important;
  /* outline: none; */
}
.image-view-page__img-description {
  color: var(--dark4);
  font-weight: 500;
  font-style: italic;
  margin-top: 0.25rem;
  margin-bottom: 2rem;
  line-height: 1;
  font-size: 1rem;
  width: 100%;
  word-wrap: break-word;
}
.image-view-page__img-description-input {
  border-radius: 2px;
  color: var(--dark4);
  font-weight: 500;
  font-style: italic;
  margin-top: 2rem;
  padding: 0.25rem 0.5rem;
  width: 100%;
  word-wrap: break-word;
}
.image-view-page__img-description-input::placeholder {
  color: var(--dark4);
  font-weight: 500;
  font-style: italic;
  width: 100%;
  word-wrap: break-word;
}
.image-view-page__description-char-count {
  font-size: 0.5rem;
  line-height: 1;
  margin-top: 0.25rem;
  margin-left: 0.4rem;
}
@media (width < 650px) {
  .image-view-page__img-title,
  .image-view-page__img-description {
    max-width: 100%;
    word-wrap: break-word;
  }
}
.image-view-page__img-tags-input-container {
}
.image-view-page__img-tags-input-and-btns-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}
.image-view-page__img-tags-input {
  border-radius: 2px;
  padding: 0.25rem 0.5rem;
}
.image-view-page__img-tags-input-red {
  border-radius: 2px;
  outline: 2px solid rgb(151, 0, 0) !important;
  padding: 0.25rem 0.5rem;
}
.image-view-page__img-tags-input-red:focus {
  outline: 2px solid rgb(151, 0, 0);
  /* outline: none; */
  padding: 0.25rem 0.5rem;
}
.image-view-page__img-tags-input-container-btns-container {
  display: flex;
  gap: 0.75rem;
  flex-wrap: wrap;
}
.image-view-page__img-tags-input-container-btns-container button {
  border: 1px solid #000;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
  padding: 0.25rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.image-view-page__img-tags-input-container-btns-container-cancel-btn {
  background-color: #b20000;
  border: none !important;
  color: #fff;
}
.image-view-page__img-tags-input-container-btns-container-submit-btn {
  background-color: green;
  border: none !important;
  color: #fff;
}
.image-view-page__img-tags-input-container-btns-container-cancel-btn:hover {
  background-color: darkred;
}
.image-view-page__img-tags-input-container-btns-container-submit-btn:hover {
  background-color: darkgreen;
}
.image-view-page__img-tags-container {
  width: var(--siteContentsMidWidth);
  padding: 0 var(--imgGalleryMarginBetweenImages);
  width: 100%;
  margin-top: 2.3rem;
  margin-bottom: 7rem;
  display: flex;
  justify-content: flex-start;
  gap: 0.45rem;
  overflow-x: auto;
  z-index: 1;
  max-height: 80px;
}
.image-view-page__img-tags-container::-webkit-scrollbar {
  display: none;
}
@media (width < 900px) {
  .image-view-page__img-tags-container {
    padding: 0;
  }
}
.image-view-page__img-tags-heading {
}
.image-view-page__tag-arrow-bounds {
  position: relative;
  width: 100%;
  max-width: var(--siteContentsMidWidth);
  padding: 0 var(--imgGalleryMarginBetweenImages);
}
.padding-left-0 {
  padding-left: 0;
}
.image-view-page__img-tags-list {
  width: 100%;
}
.image-view-page__img-tags-list ul {
  max-width: var(--siteContentsMidWidth);
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 0.5rem;
}
.image-view-page__img-tags-list a {
  color: #000;
  padding: 1.45rem 1.5rem;
}
.image-view-page__img-tags-list ul li {
  border: 2px solid var(--greyBorder1);
  font-weight: 500;
  font-size: 1.25rem;
  border-radius: 4px;
  height: 48px;
  /* padding: 0.45rem 1.5rem; */
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.image-view-page__img-tags-list ul li:hover {
  background-color: var(--ctaYellow);
  border: 2px solid var(--ctaYellow);
  transition: 0.2s;
}
@media (width < 650px) {
  .image-view-page__img-tags-list ul li {
    font-size: 1rem;
  }
}
.image-view-page__img-tags-overflowArrowLeft {
  z-index: 1;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -13%;
  left: -15px;
  padding: 0.5rem;
  padding-right: 8.5rem;
  padding-left: 2rem;
  height: 60px;
  background: linear-gradient(
    90deg,
    rgb(255, 255, 255) 30%,
    rgba(0, 212, 255, 0) 40%
  );
  animation: fadeIn 0.2s;
}
.image-view-page__img-tags-overflowArrowRight {
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  cursor: pointer;
  top: -13%;
  right: 11px;
  padding: 0.5rem;
  padding-left: 5rem;

  height: 60px;
  background: linear-gradient(
    270deg,
    rgb(255, 255, 255) 30%,
    rgba(0, 212, 255, 0) 50%
  );
  animation: fadeIn 0.2s;
}
.tagArrowGone {
  display: none !important;
}
@media (width < 900px) {
  .image-view-page__img-tags-overflowArrowLeft {
    left: -29px;
  }
  .image-view-page__img-tags-overflowArrowRight {
    right: 0px;
  }
}
@media (width < 650px) {
  .image-view-page__img-tags-overflowArrowLeft,
  .image-view-page__img-tags-overflowArrowRight {
    top: -18% !important;
  }
}
.image-view-page__img-tags-arrowIcon {
  font-size: 1rem;
  color: rgb(85, 85, 85);
}

/* .image-gallery__dropdown-and-subheading-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  margin: auto;
  margin-right: 18px;
} */

/* Media Queries (organized from less px to greater */

@media (width < 314px) {
  .carousel__overlay {
    top: 6.5rem;
  }
}

@media (width < 450px) {
  .searchHeading {
    font-size: 1.25rem !important;
    margin-top: 0 !important;
  }
}

@media (width < 559px) {
  .carousel__overlay {
    top: 8rem;
  }
}

@media (width < 710px) {
  /* .image-gallery__dropdown-and-subheading-container {
    flex-direction: column;
    align-items: flex-start;
  }
  .image-gallery__sort-filter-dropdown-container {
    margin-top: 0;
    margin-bottom: 1.5rem;
  } */
  .freeStockPhotosHeading {
    padding-bottom: 0.5rem;
    margin-left: 4px !important;
    margin-top: 2.75rem;
  }
  /* .image-gallery__dropdown-button {
    margin-left: 6px !important;
  } */
}

@media (width < 800px) {
  .sortingBarCont1 {
    border-bottom: 1px solid rgb(240, 240, 240);
    padding: 1.5rem 0px 1.5rem 0px;
  }
}

@media (width < 900px) {
  /* .image-gallery__dropdown-and-subheading-container {
    margin-right: 9px;
  } */
}

@media (width < 900px) {
  .footerContainer {
    padding: 2rem var(--sideSpacePadding);
  }
  .footer__contents-container {
    flex-direction: column;
    gap: 2.5rem;
    /* padding-top: 2rem; */
  }
  .footer__contents-container-padding-top-main-page {
    padding-top: 2rem;
  }
}

@media (width < 901px) {
  /* .image-gallery__image-container {
    margin: 7px;
  } */
  .freeStockPhotosHeading {
    margin-left: 6px;
  }
}

@media (width < 1000px) {
  .searchHeading {
    font-size: 2rem !important;
    margin-top: 0 !important;
  }
}
